import {fa} from "@/plugins/icons/utils";

import {
    faArchive,
    faFile,
    faImage,
    faMusic,
    faPencil,
    faQuestion,
} from "@fortawesome/pro-solid-svg-icons";

import {faTable as farTable} from "@fortawesome/pro-regular-svg-icons";

export default {
    fileUnknown: fa(faQuestion, {
        fixedWidth: true,
        mask: faFile,
        transform: 'shrink-8 down-1',
    }),
    fileDocument: fa(faPencil, {
        fixedWidth: true,
        mask: faFile,
        transform: 'shrink-8 down-1',
    }),
    fileSpreadsheet: fa(farTable, {fixedWidth: true}),
    fileImage: fa(faImage, {fixedWidth: true}),
    fileArchive: fa(faArchive, {fixedWidth: true}),
    fileAudio: fa(faMusic, {fixedWidth: true}),
};
