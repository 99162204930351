<template>
    <v-menu
        v-if="item.children.length"
        v-model="menuOpen"
        :bottom="!submenu"
        :offset-x="submenu"
        :offset-y="!submenu"
        :transition="submenu ? 'slide-x-transition' : 'slide-y-transition'"
        :close-on-content-click="false"
        dense
    >
        <template #activator="{on}">
            <s-list-item v-if="submenu" class="list-item" dense text v-on="on">
                {{ caption }}&nbsp;
                <v-icon class="next can-flip flip-nudge-right-4 ml-auto" :class="{flip: menuOpen}">
                    $menuRight
                </v-icon>
            </s-list-item>
            <v-btn
                v-else
                class="v-btn"
                text
                tile
                dense
                :class="{parentMenu: !submenu}"
                :width="item.width"
                v-on="on"
                @click.passive="closeMenu"
            >
                {{ caption }}&nbsp;
                <v-icon class="next can-flip flip-nudge-right-2 ml-auto" :class="{flip: menuOpen}">
                    $menuDown
                </v-icon>
            </v-btn>
        </template>

        <v-list>
            <nested-menu
                v-for="child in item.children"
                :key="child.MenuItemID"
                :item="child"
                submenu
                @closeMenu="closeMenu()"
            />
        </v-list>
    </v-menu>
    <s-list-item
        v-else-if="submenu"
        :href="item.NavigateUrl"
        :target="item.Target"
        :class="{parentMenu: !submenu}"
        class="list-item"
        text
        dense
        tile
        @click.passive="closeMenu"
    >
        {{ caption }}
    </s-list-item>
    <s-btn
        v-else
        class="v-btn"
        :href="item.NavigateUrl"
        :target="item.Target"
        :class="{parentMenu: !submenu}"
        text
        dense
        tile
        @click.passive="closeMenu"
    >
        {{ caption }}
    </s-btn>
</template>

<script>
    export default {
        name: "NestedMenu",
        props: {
            item: {
                type: Object,
                required: true,
            },
            submenu: {
                type: Boolean,
            },
        },
        data() {
            return {
                menuOpen: false,
            };
        },
        computed: {
            caption() {
                return this.item.Caption;
            },
        },
        methods: {
            closeMenu() {
                this.menuOpen = false;
                this.$emit("closeMenu");
            },
        },
    };
</script>

<style scoped lang="scss">
    .v-btn {
        text-transform: none;
        font-weight: normal;
        letter-spacing: normal;
        padding: 0 10px !important;
    }

    .v-btn.parentMenu {
        transition: color .2s;
        color: #777;

        &:hover, &:focus {
            color: black;
        }
    }
</style>
