export function uncompressDictionaries(data) {
    const keys = data.K;
    const items = [];
    for (let item of data.O) {
        const newItem = {};
        for (let i = 0; i < keys.length; i += 1) {
            newItem[keys[i]] = item[i];
        }
        items.push(newItem);
    }
    return items;
}
