export default [
    {
        path: '/profiles/students/notes/maintenance/configuration',
        component: () => import(/* webpackChunkName: "admin.profiles" */ "@/pages/profiles/students/notes/maintenance/Configuration"),
        meta: {
            menu_id: "STUDENTPROFILES",
            menu_item_id: "StudentNotesMaintConfiguration",
            title: "Student Note Configuration",
        },
    },
    {
        path: '/profiles/students/notes/maintenance/categories',
        alias: '/WebModules/StudentNotes/Maintenance/NoteCategories/MaintainStudentNoteCategories.aspx',
        component: () => import(/* webpackChunkName: "admin.profiles" */ "@/pages/profiles/students/notes/maintenance/Categories"),
        meta: {
            menu_id: "STUDENTPROFILES",
            menu_item_id: "StudentNotesMaintNoteCategories",
            title: "Student Note Categories",
        },
    },
    {
        path: '/profiles/students/notes/maintenance/pastoralchains',
        component: () => import(/* webpackChunkName: "admin.profiles" */ "@/pages/profiles/students/notes/maintenance/PastoralChains"),
        meta: {
            menu_id: "STUDENTPROFILES",
            menu_item_id: "StudentNotesMaintPastoralChains",
            title: "Student Note Pastoral Chains",
        },
    },
    {
        path: '/profiles/students/notes/anniversarynotes',
        component: () => import(/* webpackChunkName: "admin.profiles" */ "@/pages/profiles/students/notes/StudentAnniversaryNotes"),
        props: true,
        meta: {
            menu_id: "STUDENTPROFILES",
            menu_item_id: "StudentNotesDashboard",
            title: "Student Anniversary Notes",
            lastPageRedirect: null,
        },
    },
];
