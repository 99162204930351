import Vue from 'vue';
import App from './App.vue';
import router from '@/plugins/router';
import store from './store';
import "@/styles/global.scss";
import Vuetify from 'vuetify/lib/framework';
import vuetifyOptions from "./plugins/vuetify";
import Resize from "vuetify/lib/directives/resize";
import "./plugins/froala";
import "./plugins/socket";
import './plugins/testDirective';
import './components/common/global';
import log from "@/plugins/log";
import {userGlobal} from "@/plugins/user";
import {simonVersion} from "@/plugins/version";
// import './registerServiceWorker';

Vue.prototype.$user = userGlobal;
Vue.prototype.$log = log;
Vue.prototype.$simonVersion = simonVersion;
Vue.config.productionTip = false;

Vue.use(Vuetify, {
    directives: {Resize},
});
const vuetify = new Vuetify(vuetifyOptions);

new Vue({
    router,
    vuetify,
    store,
    render: h => h(App),
}).$mount('#app');
