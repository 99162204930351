<template>
    <v-list-item v-bind="computedAttrs" v-on="$listeners">
        <slot />
    </v-list-item>
</template>

<script>
    /**
     * List item - but with link detection.
     * Always provide the url in the href prop.
     */
    export default {
        name: "ListItem",
        computed: {
            computedAttrs() {
                const attrs = {...this.$attrs};
                if (attrs.href && this.$router.isRouterLink(attrs.href)) {
                    attrs.to = attrs.href;
                    delete attrs.href;
                }
                return attrs;
            },
        },
    };
</script>
