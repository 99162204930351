import Vue from 'vue';
import Vuex from 'vuex';
import dayjs from "@/plugins/dayjs";
import {useLightText} from "@/utils/colours";
import tfi from "./tfi_assessment";
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        globalLoading: 0,
        runningRequests: 0,
        now: dayjs().startOf("minute"),
        darkAppBar: false,
        canDebug: localStorage.canDebug || false,
        websiteMenus: [],
        websiteMenuItems: [],
        webSocketConnected: false,
        academicSemesterID: null,
        simEverywhere: false,
        socketSimonVersion: null,
    },
    getters: {
        hasMenuItemId(state) {
            return (menuItemId) => {
                for (const item of state.websiteMenuItems) {
                    if (item.MenuItemID === menuItemId) {
                        return true;
                    }
                }
                return false;
            };
        },
    },
    mutations: {
        setSocketConnectionState(state, value) {
            state.webSocketConnected = value;
        },
        startGlobalLoading(state) {
            state.globalLoading += 1;
        },
        endGlobalLoading(state) {
            if (state.globalLoading) {
                state.globalLoading -= 1;
            } else {
                console.warn("Attempted to send endGlobalLoading call when we weren't loading");
            }
        },
        startRequest(state) {
            state.runningRequests += 1;
        },
        finishRequest(state) {
            state.runningRequests -= 1;
        },
        updateNow(state, value) {
            state.now = value;
        },
        updateDarkAppBar(state, payload) {
            state.darkAppBar = useLightText(payload.RedValue, payload.BlueValue, payload.GreenValue);
        },
        changeDebugMode(state, value) {
            state.canDebug = value;
            if (value) {
                localStorage.canDebug = true;
            } else if ("canDebug" in localStorage) {
                delete localStorage.canDebug;
            }
        },
        setWebsiteMenuItems(state, payload) {
            state.websiteMenus = payload.websiteMenus;
            state.websiteMenuItems = payload.websiteMenuItems;
        },
        setAcademicSemester(state, payload) {
            state.academicSemesterID = payload;
        },
        setSimEverywhere(state, payload) {
            state.simEverywhere = payload;
        },
        setSocketSimonVersion(state, payload) {
            state.socketSimonVersion = payload;
        },
    },
    actions: {
        maybeUpdateNow({commit, state}) {
            const expectedTime = state.canDebug && window.nowOverride ? dayjs(window.nowOverride) : dayjs().startOf("minute");
            if (!expectedTime.isSame(state.now)) {
                commit("updateNow", expectedTime);
            }
        },
    },
    modules: {
        tfi,
    },
});
export default store;
(window.debug = window.debug || {}).on = function () {
    store.commit("changeDebugMode", true);
};
window.debug.off = function () {
    store.commit("changeDebugMode", false);
};
window.debug.sim = function () {
    store.commit("setSimEverywhere", !store.state.simEverywhere);
};
