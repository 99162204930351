export default [
    {
        path: "/course-builder/maintenance/topicsectionstemplate",
        component: () => import(/* webpackChunkName: "course-builder" */ "@/pages/course-builder/maintenance/TopicSectionsTemplate"),
        meta: {
            menu_id: "LearningAreas",
            menu_item_id: "LearningAreasMaintCourseSections",
            title: 'Maintain Course Builder Topic Sections',
        },
    },
];
