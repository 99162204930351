// eslint-disable-next-line no-restricted-imports
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(timezone);
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);
import minMax from "dayjs/plugin/minMax";
dayjs.extend(minMax);

const defaultDisplayFormat = "D/M/YYYY h:mm a";
dayjs.prototype.toString = function (style) {
    let format = defaultDisplayFormat;
    switch (style) {
        case "date":
            format = "D/M/YYYY";
            break;
        case "date-iso":
            format = "YYYY-MM-DD";
            break;
        case "time":
            format = "h:mm a";
            break;
        case undefined:
            // No argument supplied - use default.
            break;
        default:
            console.warn("Attempted to toString with invalid style", style);
            break;
    }
    return this.format(format);
};

export default dayjs;
