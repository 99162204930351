export default {
    add: {
        color: "primary",
        icon: "$add",
        text: "Add",
    },
    actions: {
        icon: "$expand",
        text: "Actions",
    },
    save: {
        color: "success",
        icon: "$save",
        text: "Save",
    },
    edit: {
        icon: "$edit",
        text: "Edit",
    },
    remove: {
        icon: "$remove",
        text: "Remove",
    },
    removeNow: {
        icon: "$remove",
        text: "Remove",
        color: "error",
    },
    download: {
        icon: "$download",
        text: "Download",
    },
    cancel: {
        color: "secondary",
        text: "Cancel",
    },
    reset: {
        icon: "$reload",
        text: "Reset",
    },
    reload: {
        icon: "$reload",
        text: "Reload",
    },
    return: {
        color: "secondary",
        icon: "$arrowLeft",
        text: "Return",
    },
    confirm: {
        color: "primary",
        text: "Confirm",
    },
    dangerousConfirm: {
        color: "error",
        text: "Confirm",
    },
    settings: {
        icon: "$settings",
        text: "Settings",
    },
    debug: {
        color: "purple darken-1",
        icon: "$debug",
        dark: true,
        text: "Debug",
    },
    preview: {
        icon: "$show",
        text: "Preview",
    },
    export: {
        icon: "$download",
        text: "Export",
    },
    undo: {
        icon: "$undo",
        text: "Undo",
    },
    copy: {
        icon: "$copy",
        text: "Copy",
    },
};
