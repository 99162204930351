export default [
    {
        path: "/surveys/",
        component: () => import(/* webpackChunkName: "connect_surveys" */ "@/pages/connect_surveys/ConnectSurveysHome"),
        meta: {
            menu_id: "CONNECTSURVEYS",
            title: "Connect Survey Home",
        },
    },
    {
        path: "/surveys/maintain/",
        component: () =>
            import(/* webpackChunkName: "connect_surveys" */ "@/pages/connect_surveys/MaintainConnectSurvey"),
        meta: {
            menu_id: "CONNECTSURVEYS",
            title: "Maintain Connect Surveys",
        },
    },
    {
        path: "/surveys/maintain/create/",
        component: () =>
            import(/* webpackChunkName: "connect_surveys" */ "@/pages/connect_surveys/MaintainConnectSurvey"),
        props: {
            create: true,
        },
        meta: {
            menu_id: "CONNECTSURVEYS",
            title: "Maintain Connect Surveys",
        },
    },
    {
        path: "/surveys/staff-category-access/",
        component: () =>
            import(/* webpackChunkName: "connect_surveys" */ "@/pages/connect_surveys/StaffCategoryAccess"),
        meta: {
            menu_id: "CONNECTSURVEYS",
            title: "Staff Category Access",
        },
    },
    {
        path: "/surveys/contents/:id(\\d+)/",
        props: true,
        component: () =>
            import(/* webpackChunkName: "connect_surveys" */ "@/pages/connect_surveys/ConnectSurveyContents"),
        meta: {
            menu_id: "CONNECTSURVEYS",
            title: "Connect Survey Contents",
        },
    },
    {
        path: "/surveys/response/:id(\\d+)/",
        props: true,
        component: () =>
            import(/* webpackChunkName: "connect_surveys" */ "@/pages/connect_surveys/ConnectSurveysResponse"),
        meta: {
            menu_id: "CONNECTSURVEYS",
            title: "Connect Survey Response",
        },
    },
];
