import maintenance from "./maintenance";

export default [
    ...maintenance,
    {
        path: "/cocurricular/",
        component: () => import(/* webpackChunkName: "cocurricular" */ "@/pages/cocurricular/Dashboard"),
        meta: {
            menu_id: "COCURRICULAR",
            title: 'Co-Curricular Activities Dashboard',
        },
    },
    {
        path: "/cocurricular/form/",
        component: () => import(/* webpackChunkName: "cocurricular" */ "@/pages/cocurricular/CoCurricularForm"),
        meta: {
            menu_id: "COCURRICULAR",
            title: 'Co-Curricular Activities Form',
        },
    },
    {
        path: "/cocurricular/form/duplicate/:duplicateId/",
        component: () => import(/* webpackChunkName: "cocurricular" */ "@/pages/cocurricular/CoCurricularForm"),
        meta: {
            menu_id: "COCURRICULAR",
            title: 'Co-Curricular Activities Form',
        },
        props: true,
    },
    {
        path: "/cocurricular/form/:id/",
        component: () => import(/* webpackChunkName: "cocurricular" */ "@/pages/cocurricular/CoCurricularForm"),
        meta: {
            menu_id: "COCURRICULAR",
            title: 'Co-Curricular Activities Form',
        },
        props: true,
    },
];
