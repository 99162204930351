<template>
    <div>
        <s-modal
            v-for="m in modals"
            :key="m.id"
            :value="m.open"
            :title="m.title"
            persistent
            small
            show-close-icon
            @cancel="resolve(m, false)"
        >
            <span class="pre-wrap">{{ m.message }}</span>
            <template #actions>
                <s-btn v-if="m.action" :type="m.dangerous ? 'dangerousConfirm' : 'confirm'" @click="resolve(m, true)">
                    {{ m.action }}
                </s-btn>
                <div v-else />
            </template>
        </s-modal>
    </div>
</template>

<script>
    import {removeFromArray} from "@/utils/array";

    const modals = [];
    let id = 0;

    export function addConfirmationModal(title, message, action, dangerous) {
        return new Promise(function (resolve) {
            const modal = {
                id: id++,
                title,
                message,
                action,
                dangerous,
                resolve,
                open: false,
            };
            modals.push(modal);
            setImmediate(() => {
                modal.open = true;
            });
        });
    }

    /** This component is included in App.vue, so that we can call an importable function to ask a question quickly without having to set up specific modals. */
    export default {
        name: "GenericConfirmation",
        data() {
            return {
                modals,
            };
        },
        methods: {
            resolve(modal, value) {
                modal.open = false;
                modal.resolve(value);
                setTimeout(() => removeFromArray(modal, this.modals), 1000);
            },
        },
    };
</script>

<style scoped>
    .pre-wrap {
        white-space: pre-wrap;
        font-size: 1.0rem;
    }
</style>
