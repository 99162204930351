export function fuzzyNumber(value) {
    let valueNum = parseInt(value, 10);

    if (isNaN(valueNum)) {
        return value;
    }
    value = valueNum.toString();
    let chopOff = 0;
    let suffix = "";
    switch (Math.floor((value.length - 1) / 3)) {
        case 0:
            break;
        case 1:
            chopOff = 3;
            suffix = "k";
            break;
        case 2:
            chopOff = 6;
            suffix = "m";
            break;
        default:
            chopOff = 9;
            suffix = "b";
            break;
    }
    return value.slice(0, value.length - chopOff) + suffix;
}


let byte_units = [
    "b",
    "kb",
    "mb",
    "gb",
];
export function bytes(value, start = 0) {
    if (!value && value !== 0) {
        return null;
    }
    let unit = start;
    while (value >= 1024 && unit + 1 < byte_units.length) {
        unit += 1;
        value /= 1024;
    }
    value = Math.round(value);
    return `${value}${byte_units[unit]}`;
}


const pascalToTitleRegex = (function () {
    try {
        // Written using a string instead of regex because of the iOS syntax error.
        // Old iOS versions do not support lookbehind.
        return new RegExp("(?<!-)(?=[A-Z])", "g");
    } catch (e) {
        // This will be wrong in cases involving hyphens, but at least it won't die.
        return /(?=[A-Z])/g;
    }
})();

export function pascalToTitle(value) {
    if (!value) {
        return value;
    }
    let segments = value.split(pascalToTitleRegex);
    let cache = [];
    let final = [];

    for (let segment of segments) {
        if (segment.length === 1) {
            cache.push(segment);
            continue;
        }
        if (cache.length) {
            final.push(cache.join(""));
            cache.length = 0;
        }
        final.push(segment.charAt(0).toUpperCase() + segment.slice(1));
    }

    if (cache.length) {
        final.push(cache.join(""));
    }

    return final.join(" ");
}

function capitaliseWord(word) {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
}

export function toTitleCase(value) {
    if (!value) {
        return value;
    }
    return value.replace(
        /\w\S*/g,
        capitaliseWord,
    );
}

export function snakeToTitleCase(value) {
    if (!value) {
        return value;
    }
    return value.split("_").map(capitaliseWord).join(" ");
}

export function kebabToTitleCase(value) {
    if (!value) {
        return value;
    }
    return value.split("-").map(capitaliseWord).join(" ");
}

const tagsToReplace = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
};
export function escapeHTML(str) {
    return str.replace(/[&<>]/g, tag => tagsToReplace[tag] || tag);
}

export const passwordRules = [
    (value) => /[0-9]+/.test(value) || "Password must contain at least 1 number",
    (value) => /[A-Z]+/.test(value) || "Password must contain at least 1 upper case letter",
    (value) => /[a-z]+/.test(value) || "Password must contain at least 1 lower case letter",
    (value) =>
        /[!@#$%^&*()_+=[{\]};:<>|./?,-]/.test(value) ||
        "Password must contain at least 1 special character: !@#$%^&*()_+=[]{};:<>|./?,-",
    (value) => /.{8}/.test(value) || "Password must be a minimum of 8 characters in length",
];
