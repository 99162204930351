export default [
    {
        path: '/debug/',
        component: () => import(/* webpackChunkName: "debug" */ "@/pages/debug/Debug"),
    },
    {
        path: '/debug/icons/',
        component: () => import(/* webpackChunkName: "debug" */ "@/pages/debug/Icons"),
    },
    {
        path: '/debug/modules/',
        component: () => import(/* webpackChunkName: "debug" */ "@/pages/debug/Modules"),
    },
    {
        path: '/debug/buttons/',
        component: () => import(/* webpackChunkName: "debug" */ "@/pages/debug/Buttons"),
    },
    {
        path: '/debug/ef/',
        component: () => import(/* webpackChunkName: "debug" */ "@/pages/debug/EntityFramework"),
    },
];
