<template>
    <v-autocomplete
        ref="field"
        v-model="searchSelection"
        accesskey="."
        :search-input.sync="searchInput"
        :items="filteredItems"
        :hide-no-data="!searchInput"
        no-data-text="Sorry, no navigation results found."
        label="Search"
        append-icon=""
        prepend-icon="$search"
        dense
        auto-select-first
        no-filter
        clearable
        @change="search"
        @blur="blurSearch"
    >
        <template
            slot="item"
            slot-scope="{item}"
        >
            <a :href="item.value" class="text-decoration-none grey--text text--darken-4" @click.prevent>
                <s-html :value="highlightText(item.text)" />
            </a>
        </template>
    </v-autocomplete>
</template>

<script>
    import {pascalToTitle, escapeHTML} from "@/utils/text";
    import {cmpFunction} from "@/utils/array";

    export default {
        name: "SearchBar",
        data() {
            return {
                searchSelection: null,
                searchInput: null,
            };
        },
        computed: {
            searchItems() {
                let list = [];
                let lastGroup = null;
                let items = [...this.$store.state.websiteMenuItems];

                function getCaptionFromUrl(url) {
                    let parts = url.split("/");
                    let interestingPart = parts[parts.length - 1];
                    return pascalToTitle(interestingPart.replace(/\.aspx$/, ""));
                }

                if (this.$store.state.canDebug) {
                    for (const route of window.vue3_routes) {
                        if (!route.userCan || route.userCan.every(actions => this.$user.can(...actions))) {
                            items.push({
                                MenuDescription: route.module,
                                SearchText: route.title,
                                NavigateUrl: route.url,
                                SearchEnabled: true,
                            });
                        }
                    }
                }

                items.sort(cmpFunction(x => x.MenuDescription));

                for (let item of items.filter(x => x.SearchEnabled && x.NavigateUrl)) {
                    if (item.MenuDescription !== lastGroup) {
                        lastGroup = item.MenuDescription;
                        list.push({
                            header: lastGroup,
                            text: lastGroup,
                        });
                    }
                    list.push({
                        text: item.SearchText || getCaptionFromUrl(item.NavigateUrl),
                        value: item.NavigateUrl,
                    });
                }
                return list;
            },
            filteredItems() {
                if (!this.searchInput) {
                    return [];
                }
                let results = [];
                let cachedHeader = null;
                const searchLowered = this.searchInput.toLowerCase();
                for (let item of this.searchItems) {
                    if (item.header) {
                        cachedHeader = item;
                        continue;
                    }
                    if (item.text.toLowerCase().indexOf(searchLowered) !== -1) {
                        if (cachedHeader) {
                            results.push(cachedHeader);
                            cachedHeader = null;
                        }
                        results.push(item);
                    }
                }
                return results;
            },
        },
        methods: {
            focus() {
                this.$refs.field.focus();
            },
            search(url) {
                if (!url) {
                    return;
                }
                if (this.$router.isRouterLink(url)) {
                    this.$router.push(url);
                } else {
                    window.location.assign(url);
                }
                this.$nextTick(() => {
                    this.searchInput = "";
                    this.searchSelection = null;
                });
            },
            blurSearch() {
                this.searchInput = "";
                this.searchSelection = null;
            },
            highlightText(text) {
                text = text || '';
                if (!this.searchInput) {
                    return escapeHTML(text);
                }
                let {start, middle, end} = this.getMaskedCharacters(text);
                start = escapeHTML(start);
                middle = escapeHTML(middle);
                end = escapeHTML(end);
                return `&nbsp;&nbsp;&nbsp;&nbsp;${start}<span class="v-list-item__mask">${middle}</span>${end}`;
            },
            getMaskedCharacters(text) {
                const searchInput = (this.searchInput || '').toString().toLocaleLowerCase();
                const index = text.toLocaleLowerCase().indexOf(searchInput);

                if (index < 0) {
                    return {
                        start: text,
                        middle: '',
                        end: '',
                    };
                }

                return {
                    start: text.slice(0, index),
                    middle: text.slice(index, index + searchInput.length),
                    end: text.slice(index + searchInput.length),
                };
            },
        },
    };
</script>
