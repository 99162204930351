import maintenance from "./maintenance";
export default [
    {
        path: '/bookings/',
        component: () => import(/* webpackChunkName: "bookings" */ "@/pages/bookings/Bookings"),
        meta: {
            menu_id: 'ResourceBookings',
        },
    },
    {
        path: '/bookings/day/:campusCode/:dateString/',
        component: () => import(/* webpackChunkName: "bookings" */ "@/pages/bookings/DayView"),
        meta: {
            menu_id: 'ResourceBookings',
            title: "Bookings Day View",
        },
        props: true,
    },
    {
        path: '/bookings/day/:campusCode/:dateString/:resourceCategoryCode/',
        component: () => import(/* webpackChunkName: "bookings" */ "@/pages/bookings/DayView"),
        meta: {
            menu_id: 'ResourceBookings',
            title: "Bookings Day View",
        },
        props: true,
    },
    ...maintenance,
];
