<template>
    <div class="panel-wrapper" :class="{fullHeight}">
        <v-card
            class="panel"
            :class="{loading: loading && !error}"
            :tile="tile"
            :min-height="minHeight"
            :color="backgroundColour"
        >
            <v-card-title class="heading text-no-wrap" :title="title">
                <!-- @slot Override the whole title bar. You probably want either title.text or title.append though. -->
                <slot name="title" v-bind="{title}">
                    <div class="title">
                        <!-- @slot If you want to override the text on the titlebar, but without changing the icons. -->
                        <slot name="title.text" :title="title">
                            {{ title }}
                        </slot>
                    </div>
                    <v-spacer />
                    <div>
                        <v-theme-provider dark>
                            <!-- @slot This will appear to the right of the title, most useful for icons (such as 'close' on a modal) -->
                            <slot name="title.append" v-bind="{title}" />
                        </v-theme-provider>
                    </div>
                </slot>
            </v-card-title>
            <div class="headingUnderline" />
            <v-card-text class="card-text panel-text" :class="{noPadding}">
                <v-container v-if="error" :style="textStyle">
                    <v-alert type="error">
                        A critical error occurred while loading this content.
                    </v-alert>
                    To help us fix this issue, please ask your teacher or SIMON administrator
                    to lodge a support request with answers to the following questions:
                    <ul>
                        <li>What is the address of the SIMON page you were using?</li>
                        <li>What specific action caused this error to occur?</li>
                        <li>Can you can make it happen again?</li>
                        <li>Can you can make it happen again on somebody else's computer?</li>
                    </ul>
                    <slot name="error" />
                </v-container>
                <div v-else class="contents" :style="textStyle">
                    <!-- @slot The content inside the panel -->
                    <slot />
                </div>
            </v-card-text>
            <v-card-actions>
                <!-- @slot This slot is beneath the content, for buttons. If a max height is set, then this will be below the scrolling area. -->
                <slot name="actions" />
            </v-card-actions>
        </v-card>
        <div v-if="loading" class="loader-wrapper">
            <div class="loader" />
        </div>
    </div>
</template>

<script>
    /**
     A core SIMON building block. This gives us the 'bootstrap panel' appearance that occurs throughout SIMON.
    */
    export default {
        name: "Panel",
        props: {
            /**
             The text displayed in the titlebar of the panel.
            */
            title: {
                type: String,
                required: true,
            },
            /**
             Blurs the contents of this page, and overlays a loading bar.
             Use this when loading page-wide data that the page is useless without.
            */
            loading: {
                type: Boolean,
            },
            /**
             Puts the panel into an error state. Where useful, assign the exception directly as that could help
             future log gathering attempts. Don't forget to also log to console.error.
            */
            error: {
                type: [String, Error],
                default: null,
            },
            /**
             Limit the size of the content area of this panel, introducing scrollbars where required.
            */
            textMaxHeight: {
                type: [String, Number],
                default: null,
            },
            /**
             As per [v-card](https://vuetifyjs.com/en/components/cards/#api), remove rounded corners and drop shadow.
            */
            tile: {
                type: Boolean,
            },
            /**
             Forces the panel to always be at least this large.
            */
            minHeight: {
                type: [Number, String],
                default: null,
            },
            /**
             Don't include padding in the content area of the panel
            */
            noPadding: {
                type: Boolean,
            },
            /**
             If set, the panel will take up the full available space, and scroll inside.
            */
            fullHeight: {
                type: Boolean,
            },
            /**
             Override the background of the content area.
            */
            backgroundColour: {
                type: String,
                default: null,
            },
        },
        computed: {
            textStyle() {
                if (!this.textMaxHeight) {
                    return {};
                }

                let maxHeight = this.textMaxHeight;
                if (typeof maxHeight === "number") {
                    maxHeight += "px";
                }

                return {
                    overflowY: 'auto',
                    maxHeight,
                };
            },
        },
    };
</script>

<style scoped lang="scss">
    @import "@/styles/helpers";

    .heading {
        padding: 5px 2px 5px 15px;
        margin: 0;
        font-size: 17px;
        color: white;
        background-color: var(--WDPanelHeading);
        background-image: linear-gradient(0deg, rgba(255,255,255,0), rgba(255,255,255,.2));
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
        flex-wrap: nowrap;

        .title {
            min-width: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .headingUnderline {
        border-top: 2px solid var(--WDUnderline);
        margin-top: -4px;
    }
    .contents {
        @include simon_scrollbar;
        padding-right: 1em;
        position: relative;
        min-height: 120px;
        padding-bottom: 10px;
        padding-top: 3px;
    }
    .fullHeight .contents {
        min-height: 0;
    }
    .panel-wrapper {
        position: relative;
    }
    .panel {
        transition: filter .2s, opacity .2s;
        position: relative;

        .noPadding {
            padding: 0;

            .contents {
                padding: 0;
            }
        }
    }

    .fullHeight.panel-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;

        .panel, .panel-text {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            overflow: auto;
        }

        .panel-text {
            top: 44px
        }

        .contents {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            overflow: auto;
        }
    }

    .loading {
        filter: blur(3px);
        opacity: .65;
    }
    .loader-wrapper {
        position: absolute;
        padding-top: 85px;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }
    .loader,
    .loader:after {
        border-radius: 50%;
        width: 10em;
        height: 10em;
    }
    .loader {
        margin: 30px auto;
        font-size: 5px;
        position: relative;
        text-indent: -9999em;
        border-top: 1.1em solid var(--WDUnderline);
        border-right: 1.1em solid var(--WDPanelHeading);
        border-bottom: 1.1em solid var(--WDPanelHeading);
        border-left: 1.1em solid var(--WDPanelHeading);
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: load8 1.1s infinite linear;
        animation: load8 1.1s infinite linear;
        box-shadow: #fff 0 0 6px 4px;
    }
    @-webkit-keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
</style>
