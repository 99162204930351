import store from "@/store/student_profiles";

const staffChildren = [
    {
        path: "",
        redirect: () => {
            return "details/";
        },
    },
    // {
    //     path: "details/",
    //     component: () => import(/* webpackChunkName: "profiles" */ "@/pages/profiles/common/PersonalDetails"),
    //     props: true,
    // },
    // {
    //     path: "employment/",
    //     component: () => import(/* webpackChunkName: "profiles" */ "@/pages/profiles/staff/EmploymentInfo"),
    //     props: true,
    // },
];

const defaultProfileUrl = "aspx/GeneralInformation/StudentDashboard.aspx/";
const studentChildren = [
    {
        path: "",
        redirect: () => {
            // Redirect to the last student profile page we visited
            return (localStorage.lastStudentProfilesPage || defaultProfileUrl).replace(/^legacy\//, 'aspx/');
        },
    },
    {
        path: 'settings/',
        component: () => import(/* webpackChunkName: "profiles" */ "@/pages/profiles/common/UserSettings"),
        meta: {
            menu_id: "STUDENTPROFILES",
        },
    },
    {
        path: 'summaries/heartbeat',
        component: () => import(/* webpackChunkName: "profiles" */ "@/pages/profiles/students/summaries/StudentHeartbeat"),
        props: true,
        meta: {
            menu_id: "STUDENTPROFILES",
            menu_item_id: "StudentProfileView",
            item_key: "StudentHeartbeat",
            title: "Student Heartbeat",
            lastPageRedirect: null,
        },
    },
    {
        path: 'notes/dashboard',
        component: () => import(/* webpackChunkName: "admin.profiles" */ "@/pages/profiles/students/notes/StudentNotesDashboard"),
        props: true,
        meta: {
            menu_id: "STUDENTPROFILES",
            menu_item_id: "StudentNotesDashboard",
            item_key: "ViewNotes",
            title: "Student Notes Dashboard",
            lastPageRedirect: null,
        },
    },
    {
        path: 'notes/:categoryId/list/',
        component: () => import(/* webpackChunkName: "admin.profiles" */ "@/pages/profiles/students/notes/StudentNotesList"),
        props: true,
        meta: {
            menu_id: "STUDENTPROFILES",
            menu_item_id: "StudentNotesDashboard",
            item_key: "ViewNotes",
            title: "Student Notes",
            lastPageRedirect: 'notes/dashboard/',
        },
    },
    {
        path: 'notes/:categoryId/list/:noteId/',
        component: () => import(/* webpackChunkName: "admin.profiles" */ "@/pages/profiles/students/notes/StudentNotesList"),
        props: true,
        meta: {
            menu_id: "STUDENTPROFILES",
            menu_item_id: "StudentNotesDashboard",
            item_key: "ViewNotes",
            title: "Student Notes",
            lastPageRedirect: 'notes/dashboard/',
        },
    },
    {
        path: "cocurricular/",
        component: () => import(/* webpackChunkName: "profiles" */ "@/pages/profiles/students/cocurricular/CoCurricular"),
        props: true,
        meta: {
            title: "Co-Curricular",
        },
    },
    {
        path: "cocurricular/:formId/",
        component: () => import(/* webpackChunkName: "profiles" */ "@/pages/profiles/students/cocurricular/CoCurricularForm"),
        props: true,
        meta: {
            title: "Co-Curricular Form",
        },
    },
    {
        path: "aspx/:url(.+)/",
        component: () => import(/* webpackChunkName: "profiles" */ "@/pages/common/LegacyWindow"),
        props: route => ({
            prefix: "/WebModules/Profiles/Student/",
            url: route.params.url.replace(/\/$/, ''), // cut out trailing slash if it added one
            extraQueryParams: {
                UserGUID: store.state.guid,
            },
        }),
        meta: {
            title() {
                return store.state.title || "Loading";
            },
            lastPageRedirect: null,
        },
    },
];

const commonChildren = [
    // {
    //     path: "details/",
    //     component: () => import(/* webpackChunkName: "profiles" */ "@/pages/profiles/common/PersonalDetails"),
    //     props: true,
    //     meta: {
    //         title: "Personal Details",
    //     },
    // },
];

const staffProfileProps = route => ({
    staffMode: true,
    ...route.params,
});

export default [
    {
        path: '/profiles/students/',
        component: () => import(/* webpackChunkName: "profiles" */ "@/pages/profiles/students/StudentProfiles"),
        meta: {
            menu_id: "STUDENTPROFILES",
        },
    },
    {
        path: '/profiles/students/me/',
        component: () => import(/* webpackChunkName: "profiles" */ "@/pages/profiles/students/Profile"),
        children: [...studentChildren, ...commonChildren],
        meta: {
            menu_id: "STUDENTPROFILES",
            title: "Students",
        },
    },
    {
        path: '/profiles/students/:id(\\d+)/',
        component: () => import(/* webpackChunkName: "profiles" */ "@/pages/profiles/students/Profile"),
        props: true,
        children: [...studentChildren, ...commonChildren],
        meta: {
            menu_id: "STUDENTPROFILES",
            title: "Students",
        },
    },
    {
        path: '/profiles/staff/me/',
        component: () => import(/* webpackChunkName: "profiles" */ "@/pages/profiles/staff/StaffProfiles"),
        meta: {
            menu_id: "STAFFPROFILES",
            title: "Staff",
        },
    },
    {
        path: '/profiles/staff/:id(\\d+)/',
        component: () => import(/* webpackChunkName: "profiles" */ "@/pages/profiles/staff/StaffProfiles"),
        props: staffProfileProps,
        children: [...staffChildren, ...commonChildren],
        meta: {
            menu_id: "STAFFPROFILES",
            title: "Staff",
        },
    },
];
