<template>
    <v-btn
        v-if="visible"
        v-test:[`${type}Button`]
        v-bind="attrs"
        :icon="iconStyle"
        :type="submit ? 'submit' : 'button'"
        :elevation="round ? '2' : $attrs['elevation']"
        :class="{ignoreActive}"
        v-on="$listeners"
    >
        <v-icon
            v-if="selectedIcon"
            :x-large="$attrs['x-large']"
            :large="$attrs.large"
            :small="$attrs.fab === undefined && $attrs.small"
            :x-small="round || $attrs.fab === undefined && $attrs['x-small']"
            :left="!!($slots.default || defaultText)"
            :color="iconColor"
        >
            {{ selectedIcon }}
        </v-icon>
        <!-- @slot Override the text on the button -->
        <slot>{{ defaultText }}</slot>
    </v-btn>
</template>

<script>
    import types from "./btn-types";

    /**
    This component is essentially a wrapper around the vuetify [v-btn](https://vuetifyjs.com/en/components/buttons/)
    component. It also adds our styling, so should be used in preference to the vuetify variant.
    All the functionality of v-btn should still be available when using this wrapper.
    */
    export default {
        name: "Btn",
        props: {
            /**
             Use in conjunction with menuItemId to make this button into a link to a website menu item.
             The button will automatically be disabled if the user has no access to the target page.
            */
            menuId: {
                type: String,
                default: null,
            },
            /**
             See menuId
            */
            menuItemId: {
                type: String,
                default: null,
            },
            /**
             If the menu item isn't found, hide the button (rather than the default of disabling it)
            */
            menuItemHide: {
                type: Boolean,
            },
            /**
             Add the icon of this type to the left of the button.
            */
            icon: {
                type: String,
                default: null,
            },
            /**
             Equivalent to v-btn's [icon](https://vuetifyjs.com/en/components/buttons/#api) prop.
             Also hides the default text on a button.
            */
            iconStyle: {
                type: Boolean,
            },
            /**
             Styles this button in accordance to our settings for the specified type.
             Eg, "save" will make the button green and add a save icon.
             Check the object at the top of the script tag in this file to see the options available.
            */
            type: {
                type: String,
                default: null,
            },
            /**
              * Sets the type of the underlying button to "submit" (as opposed to "button").
              *
              * By settings this, you can avoid linking up the submit function to both a button and the form.
              */
            submit: {
                type: Boolean,
            },
            /**
             Overrides the color to primary. This is a shortcut way to conditionally make a button primary.
            */
            primary: {
                type: Boolean,
            },
            /**
             * By default, a btn with a link will fade slightly when you're on the page it links to.
             * Settings this will suppress that behaviour.
             */
            ignoreActive: {
                type: Boolean,
            },
            /** Force the debug button to show, even when not in debug mode */
            forceDebug: {
                type: Boolean,
            },
            /** TODO - Put description here */
            round: {
                type: Boolean,
            },
        },
        computed: {
            attrs() {
                const attrs = {...this.$attrs};
                this.menuItemAttrs(attrs);
                this.typeStyleAttrs(attrs);

                if (this.primary) {
                    attrs.color = "primary";
                }
                if (attrs.href && this.$router.isRouterLink(attrs.href)) {
                    attrs.to = attrs.href;
                    attrs.href = null;
                }
                if (this.round) {
                    attrs.color = null;
                    attrs.fab = true;
                    attrs.xSmall = true;
                }
                return attrs;
            },
            typeStyle() {
                if (!this.type) {
                    return null;
                }
                if (types[this.type]) {
                    return types[this.type];
                }
                console.warn("The type", this.type, "is invalid!");
                return null;
            },
            selectedIcon() {
                if (this.icon) {
                    return this.icon;
                }
                if (this.typeStyle) {
                    return types[this.type].icon;
                }
                return null;
            },
            menuItem() {
                if (!this.menuId || !this.menuItemId) {
                    return null;
                }
                for (let item of this.$store.state.websiteMenuItems) {
                    if (item.MenuID === this.menuId && item.MenuItemID === this.menuItemId) {
                        return item;
                    }
                }
                if (this.$store.state.canDebug) {
                    console.warn("Unknown menu item!", this.menuId, this.menuItemId);
                }
                return null;
            },
            visible() {
                if (this.type === "debug" && (!this.forceDebug && !this.$store.state.canDebug)) {
                    return false;
                }
                return !(this.menuItemHide && this.menuId && this.menuItemId && this.menuItem === null);
            },
            defaultText() {
                return this.typeStyle && !this.iconStyle && this.$attrs.fab === undefined && !this.round ? this.typeStyle.text : "";
            },
            iconColor() {
                if (!this.round) {
                    return null;
                }

                return this.$attrs.color || types[this.type] && types[this.type].color;
            },
        },
        methods: {
            menuItemAttrs(attrs) {
                if (this.menuItem) {
                    // read from menu items, decide whether to use 'go' or 'href'.
                    // Default to go for our access denied page if it's not here.
                    attrs.href = this.menuItem.NavigateUrl;
                } else if (this.menuId || this.menuItemId) {
                    // Force the button to be disabled if we don't have enough information to follow this link.
                    attrs.disabled = true;
                }
            },
            typeStyleAttrs(attrs) {
                if (this.typeStyle) {
                    for (let k of Object.keys(this.typeStyle)) {
                        if (k === "icon" || k === 'text' || k in attrs) {
                            // Overridden or handled elsewhere
                            continue;
                        }
                        attrs[k] = this.typeStyle[k];
                    }
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    .v-btn--active::before {
        opacity: 0 !important;
    }
</style>
