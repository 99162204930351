export default [
    {
        path: "/admin/site/actions/",
        component: () => import(/* webpackChunkName: "admin.site" */ "@/pages/admin/site/actions/Overview"),
        meta: {
            menu_id: "SITEADMIN",
        },
    },
    {
        path: "/admin/site/actions/staff-roles/",
        component: () => import(/* webpackChunkName: "admin.site" */ "@/pages/admin/site/actions/ActionStaffRoles"),
        meta: {
            menu_id: "SITEADMIN",
            title: "Actions - Staff Roles",
        },
    },
    {
        path: "/admin/site/actions/staff-roles/:roleId/",
        component: () => import(/* webpackChunkName: "admin.site" */ "@/pages/admin/site/actions/ActionStaffRoles"),
        meta: {
            menu_id: "SITEADMIN",
            title: "Actions - Staff Categories",
        },
        props: true,
    },
    {
        path: "/admin/site/actions/user-types/",
        component: () => import(/* webpackChunkName: "admin.site" */ "@/pages/admin/site/actions/ActionUserTypes"),
        meta: {
            menu_id: "SITEADMIN",
            title: "Actions - User Types",
        },
    },
    {
        path: "/admin/site/actions/user-types/:userType/",
        component: () => import(/* webpackChunkName: "admin.site" */ "@/pages/admin/site/actions/ActionUserTypes"),
        meta: {
            menu_id: "SITEADMIN",
            title: "Actions - User Types",
        },
        props: true,
    },
];
