export default [
    {
        path: '/admin/school/',
        component: () => import(/* webpackChunkName: "admin" */ "@/pages/admin/school/DashboardMenu"),
        meta: {
            menu_id: "SCHOOLADMIN",
            menu_item_id: "SchoolAdmin",
            title: "School Administration",
        },
    },
    {
        path: '/admin/school/setup/school-information',
        alias: '/WebModules/SchoolAdministration/SchoolSetup/Campuses/MaintainCampuses',
        component: () => import(/* webpackChunkName: "admin.school.setup.school-information" */ "@/pages/admin/school/setup/SchoolInformation"),
        meta: {
            menu_id: "SCHOOLADMIN",
            menu_item_id: "SchoolAdminInformation",
            title: "School Information",
        },
    },
    {
        path: '/admin/school/:parentMenuId/',
        component: () => import(/* webpackChunkName: "admin" */ "@/pages/admin/school/DashboardMenu"),
        meta: {
            menu_id: "SCHOOLADMIN",
            title: "Menu",
        },
        props: true,
    },
    {
        path: '/admin/school/enquiry/enquiryWorkdesk',
        component: () => import(/* webpackChunkName: "admin.school" */ "@/pages/admin/school/enquiry/EnquiryWorkDesk"),
        meta: {
            menu_id: "SCHOOLADMIN",
            menu_item_id: "Enquiry",
            title: "Enquiry Work Desk",
        },
    },
    {
        path: '/admin/school/enquiry/addEnquiry',
        component: () => import(/* webpackChunkName: "admin.school" */ "@/pages/admin/school/enquiry/Enquiry"),
        meta: {
            menu_id: "SCHOOLADMIN",
            menu_item_id: "Enquiry",
            title: "Add Enquiry",
            disable: false,
        },
    },
    {
        path: '/admin/school/enquiry/editEnquiry/:enquiryID/',
        component: () => import(/* webpackChunkName: "admin.school" */ "@/pages/admin/school/enquiry/Enquiry"),
        meta: {
            menu_id: "SCHOOLADMIN",
            menu_item_id: "Enquiry",
            title: "Manage Enquiry",
            disable: true,
        },
    },
    {
        path: '/admin/school/enquiry/maintenance/enquiryFormTemplate',
        component: () => import(/* webpackChunkName: "admin.school" */ "@/pages/admin/school/enquiry/maintenance/EnquiryFormTemplate"),
        meta: {
            menu_id: "SCHOOLADMIN",
            menu_item_id: "EnquiryFormTemplate",
            title: "Enquiry Form Template",
        },
    },
    {
        path: '/admin/school/enquiry/maintenance/enquiryStatus',
        component: () => import(/* webpackChunkName: "admin.school" */ "@/pages/admin/school/enquiry/maintenance/EnquiryStatus"),
        meta: {
            menu_id: "SCHOOLADMIN",
            menu_item_id: "EnquiryStatus",
            title: "Enquiry Status",
        },
    },
    {
        path: '/admin/school/timetable/builder/',
        component: () => import(/* webpackChunkName: "admin.system" */ "@/pages/admin/school/timetable/TimetableBuilder"),
        meta: {
            menu_id: 'SCHOOLADMIN',
            menu_item_id: 'TimetableMaintenanceBuilder',
            title: 'Timetable Builder',
        },
    },
    {
        path: '/admin/school/setup/boarding-houses/',
        component: () => import(/* webpackChunkName: "admin.school" */ "@/pages/admin/school/setup/BoardingHouses"),
        alias: '/WebModules/SchoolAdministration/SchoolSetup/BoardingHouses/MaintainBoardingHouses.aspx',
        meta: {
            menu_id: "SCHOOLADMIN",
            menu_item_id: "SchoolAdminBoardingHouse",
            title: "Boarding Houses",
        },
    },
    {
        path: '/admin/school/setup/maintain-houses/',
        alias: '/WebModules/SchoolAdministration/SchoolSetup/Houses/MaintainHouses.aspx',
        component: () => import(/* webpackChunkName: "admin.school" */ "@/pages/admin/school/setup/MaintainHouses"),
        meta: {
            menu_id: "SCHOOLADMIN",
            menu_item_id: "SchoolAdminHouse",
            title: "Maintain Houses",
        },
    },
    {
        path: '/admin/school/setup/school/',
        component: () => import(/* webpackChunkName: "admin.school" */ "@/pages/admin/school/setup/School"),
        alias: '/WebModules/SchoolAdministration/SchoolSetup/Schools/MaintainSchools.aspx',
        meta: {
            menu_id: "SCHOOLADMIN",
            menu_item_id: "SchoolAdminSchools",
            title: "Maintain Schools",
        },
    },
    {
        path: '/admin/school/setup/domain-components/',
        component: () => import(/* webpackChunkName: "admin.school" */ "@/pages/admin/school/setup/DomainComponents"),
        alias: '/WebModules/SchoolAdministration/SchoolSetup/DomainComponents/MaintainDomainComponents.aspx',
        meta: {
            menu_id: "SCHOOLADMIN",
            menu_item_id: "ViewDomainComponent",
            title: "Domain Component",
        },
    },
];
