/* eslint vue/match-component-file-name: 0 */
import Vue from 'vue';

// UI
import Html from "./SHtml";
import Link from "./SLink";
import ListItem from "./ListItem";
import Modal from "./Modal";
import Page from "./Page";

Vue.component("SHtml", Html);
Vue.component("SLink", Link);
Vue.component("SListItem", ListItem);
Vue.component("SModal", Modal);
Vue.component("SPage", Page);

// Forms
import Autocomplete from "./Autocomplete";
import Btn from "./Btn";
import Form from "./SForm";
import ModalForm from "./ModalForm";
import Select from "./SSelect";
import TextField from "./TextField";
import Textarea from "./STextarea";

Vue.component("SAutocomplete", Autocomplete);
Vue.component("SBtn", Btn);
Vue.component("SForm", Form);
Vue.component("SModalForm", ModalForm);
Vue.component("SSelect", Select);
Vue.component("STextField", TextField);
Vue.component("STextarea", Textarea);
