export default [
    {
        path: '/profiles/students/summaries/maintenance/configuration',
        component: () => import(/* webpackChunkName: "admin.profiles" */ "@/pages/profiles/students/summaries/maintenance/StudentHeartbeatConfiguration"),
        meta: {
            menu_id: "STUDENTPROFILES",
            menu_item_id: "StudentHeartbeatMaintConfiguration",
            title: "Student Heartbeat Configuration",
        },
    },
];
