import icons from "./icons";
import {Resize} from 'vuetify/lib/directives';

export default {
    directives: {
        Resize,
    },
    theme: {
        options: {
            customProperties: true,
        },
    },
    icons: {
        iconfont: null,
        values: icons,
    },
};
