export default [
    {
        path: '/admin/communication/permissions/',
        component: () => import(/* webpackChunkName: "admin.communication" */ "@/pages/admin/communication/Permissions"),
        alias: '/WebModules/Communication/Maintenance/Permissions.aspx',
        meta: {
            menu_id: 'COMMCENTRE',
            title: 'Permissions - Communication Centre',
        },
    },
    {
        path: '/admin/communication/header/',
        component: () => import(/* webpackChunkName: "admin.communication" */ "@/pages/admin/communication/HeaderFooter"),
        alias: '/WebModules/Communication/Maintenance/Header.aspx',
        props: {
            header: true,
        },
        meta: {
            menu_id: 'COMMCENTRE',
            title: 'Header - Communication Centre',
        },
    },
    {
        path: '/admin/communication/footer/',
        component: () => import(/* webpackChunkName: "admin.communication" */ "@/pages/admin/communication/HeaderFooter"),
        alias: '/WebModules/Communication/Maintenance/Footer.aspx',
        props: {
            header: false,
        },
        meta: {
            menu_id: 'COMMCENTRE',
            title: 'Footer - Communication Centre',
        },
    },
    {
        path: '/admin/communication/config/',
        component: () => import(/* webpackChunkName: "admin.communication" */ "@/pages/admin/communication/Config"),
        alias: '/WebModules/Communication/Maintenance/Config.aspx',
        props: {
            header: false,
        },
        meta: {
            menu_id: 'COMMCENTRE',
            title: 'Config - Communication Centre',
        },
    },
];
