<template>
    <div v-if="$route.meta.hideUI">
        <router-view />
    </div>
    <v-app v-else-if="user.loaded">
        <side-nav
            ref="sideNav"
            :version="simonVersion"
        />
        <v-app-bar
            app
            class="topBar"
            :class="{atWorkdesk: isAtWorkdesk}"
            :dark="isAtWorkdesk && $store.state.darkAppBar"
            clipped-right
            clipped-left
        >
            <v-slide-x-transition mode="out-in">
                <v-icon :key="menuIcon" x-large>
                    {{ menuIcon }}
                </v-icon>
            </v-slide-x-transition>
            <v-slide-x-transition mode="out-in">
                <div
                    v-if="topLogo"
                    key="defaultLogo"
                    class="schoolLogo"
                />
                <span v-else :key="menuIcon" class="ml-3 text-body-1 text-sm-h5 font-weight-light">
                    {{ menuTitle }}
                </span>
            </v-slide-x-transition>
            <v-spacer />
            <div class="searchBar hidden-sm-and-down">
                <search-bar ref="search" class="app-bar-colour" />
            </div>
            <s-btn v-if="$store.state.canDebug" icon="$debug" icon-style @click="debugMenuOpen = !debugMenuOpen" />
            <s-btn
                :icon="$store.state.webSocketConnected ? '$home' : '$loading'"
                icon-style
                href="/"
                class="app-bar-colour"
                :color="
                    $store.state.webSocketConnected === null ? 'warning' :
                    $store.state.webSocketConnected ? null : 'error'
                "
            />
            <alerts class="app-bar-colour" />
            <alerts
                v-if="!$user.isStaff"
                class="app-bar-colour"
                messages
                :alert-events="messageEvents"
            />
            <v-app-bar-nav-icon
                class="app-bar-colour"
                :color="user.masquerade ? 'red' : null"
                @click.stop="$refs.sideNav.toggle()"
            />
        </v-app-bar>

        <v-main class="content_wrapper">
            <toolbar
                class="fixed-toolbar"
                :menu-id="noAccess ? 'workdesk' : menuId"
                :page-title="pageTitleWithoutVersion"
            />
            <div class="content">
                <v-slide-y-reverse-transition>
                    <v-card
                        v-if="versionWarning"
                        class="version-warning headline text-center"
                        color="orange"
                        @click="versionRefresh"
                    >
                        {{ versionWarning }}
                        <v-btn right @click="versionRefresh">
                            Reload
                        </v-btn>
                    </v-card>
                </v-slide-y-reverse-transition>
                <v-slide-y-transition mode="out-in">
                    <div v-if="user.failure">
                        <v-alert type="error">
                            {{ user.failure }}
                            <template #close>
                                <v-btn small right @click="versionRefresh">
                                    Retry
                                </v-btn>
                            </template>
                        </v-alert>
                    </div>
                    <access-denied v-else-if="!loading && noAccess" />
                    <router-view v-else-if="!loading" />
                </v-slide-y-transition>
            </div>
        </v-main>
        <generic-confirmation />
        <generic-snackbar />
        <debug v-if="$store.state.canDebug" v-model="debugMenuOpen" />
    </v-app>
</template>

<script>
    import Toolbar from "@/components/application/Toolbar";
    import SearchBar from "@/components/application/SearchBar";
    import AccessDenied from "@/pages/errors/AccessDenied";
    import SideNav from "@/components/application/SideNav";
    import Alerts from "@/components/application/Alerts";
    import GenericConfirmation from "@/components/application/modals/GenericConfirmation";
    import GenericSnackbar from "@/components/application/modals/GenericSnackbar";
    import {snakeToTitleCase} from "@/utils/text";
    import user from "@/plugins/user";
    import {postAsync} from "@/utils/requests";
    import {menuIcons} from "./plugins/icons/modules";
    import {simonVersion} from "@/plugins/version";

    const developmentVersion = "DEVELOPMENT";

    export default {
        name: "App",
        components: {
            SearchBar,
            AccessDenied,
            Toolbar,
            SideNav,
            Alerts,
            GenericConfirmation,
            GenericSnackbar,
            Debug: () => import(/* webpackChunkName: "debug" */ "@/components/application/Debug"),
        },
        mixins: [user],
        provide() {
            return {
                pageTitleWithoutVersion: this.pageTitleWithoutVersionData,
            };
        },
        data() {
            return {
                debugMenuOpen: !localStorage.debugMenuMinimised,
                simonVersion,
                setNowInterval: null,
                pageTitleWithoutVersionData: {title: ""},
                messageEvents: {
                    click(event) {
                        let element = event.target;
                        while (element !== null) {
                            if (element.tagName === "A") {
                                event.preventDefault();
                                event.stopPropagation();
                                postAsync(
                                    "Default.asmx/ReadNotification",
                                    {notificationRecipientID: element.dataset.alert},
                                ).finally(() => {
                                    window.location.href = element.dataset.url;
                                });
                                return;
                            }
                            element = element.parentElement;
                        }
                    },
                },
            };
        },
        computed: {
            websiteMenus() {
                return this.$store.state.websiteMenus;
            },
            websiteMenuItems() {
                return this.$store.state.websiteMenuItems;
            },
            loading() {
                return !this.user.loaded || this.$store.state.globalLoading;
            },
            noAccess() {
                if (this.websiteMenus.map(x => x.MenuID.toLowerCase()).indexOf(this.menuId) === -1) {
                    return true;
                }
                if (this.$route.meta.roles) {
                    for (const role of this.$route.meta.roles) {
                        if (!this.$user.hasRole(role)) {
                            return true;
                        }
                    }
                }
                if (this.$route.meta.menu_item_id) {
                    const menu_item_id = this.$route.meta.menu_item_id.toLowerCase();
                    for (const item of this.websiteMenuItems) {
                        if (item.MenuItemID.toLowerCase() === menu_item_id) {
                            return false;
                        }
                    }
                    return true;
                }
                return false;
            },
            menuId() {
                for (const match of [...this.$route.matched].reverse()) {
                    if (match.meta.menu_id) {
                        return match.meta.menu_id.toLowerCase();
                    }
                }
                return "workdesk";
            },
            menuIcon() {
                return menuIcons[this.menuId.toUpperCase()] || menuIcons.default;
            },
            menuTitle() {
                if (this.menuId === "workdesk") {
                    // suppress text on workdesk, as we'll show the school logo instead
                    return "";
                }
                for (let menu of this.websiteMenus) {
                    if (menu.MenuID.toLowerCase() === this.menuId) {
                        return menu.MenuDescription;
                    }
                }
                return "";
            },
            isAtWorkdesk() {
                return this.menuId === "workdesk";
            },
            pageTitleWithoutVersion() {
                const callOrReturn = (obj) => {
                    if (obj instanceof Function) {
                        return obj(this);
                    }
                    return obj;
                };

                let title = this.$route.matched.map(
                    match => callOrReturn(match.meta.title) || snakeToTitleCase(match.path.split("/").slice(-1)[0]),
                ).reverse().join(" - ");
                if (!title) {
                    title = "SIMON";
                }
                return title;
            },
            pageTitle() {
                let title = this.pageTitleWithoutVersion;
                if (this.simonVersion !== developmentVersion) {
                    title += ` - v${this.simonVersion}`;
                } else {
                    title += " - Development";
                }
                return title;
            },
            topLogo() {
                return this.menuId === "workdesk";
            },
            dateString() {
                return this.$store.state.now.toString("date");
            },
            versionWarning() {
                const socketSimonVersion = this.$store.state.socketSimonVersion;
                if (!socketSimonVersion || this.simonVersion === developmentVersion) {
                    return null;
                }
                if (socketSimonVersion !== this.simonVersion) {
                    return "SIMON has been updated. Please reload the page.";
                }
                return null;
            },
        },
        watch: {
            pageTitle: {
                immediate: true,
                handler(newValue) {
                    document.title = newValue;
                },
            },
            loading(value) {
                document.getElementById("start-up-progress-bar").style.display = value ? "flex" : "none";
            },
            pageTitleWithoutVersion: {
                immediate: true,
                handler(value) {
                    this.pageTitleWithoutVersionData.title = value;
                },
            },
            '$store.state.now'(value) {
                if (value.toString('date') !== this.$user.getConfig('dateUpdated')) {
                    this.loadUserData(false);
                }
            },
            debugMenuOpen(value) {
                if (value) {
                    delete localStorage.debugMenuMinimised;
                } else {
                    localStorage.debugMenuMinimised = 1;
                }
            },
        },
        async created() {
            this.setNowInterval = setInterval(() => this.$store.dispatch("maybeUpdateNow"), 1000);
            await Promise.all([this.loadUserData(), this.updateAppBar()]);
            setTimeout(() => {
                this.registerUserLogin();
            }, 300000);
        },
        destroyed() {
            clearInterval(this.setNowInterval);
        },
        methods: {
            async updateAppBar() {
                let colours;
                try {
                    colours = await postAsync("Default.asmx/GetColours", {styleSet: "SIMONDefault"});
                } catch (error) {
                    // No action.
                    return;
                }
                for (let colour of colours) {
                    if (colour.StyleKey === "WDBannerTopColour") {
                        this.$store.commit("updateDarkAppBar", colour);
                        break;
                    }
                }
            },
            versionRefresh() {
                location.reload(true);
            },
            async registerUserLogin() {
                try {
                    await postAsync(
                        "Default.asmx/RegisterUserLogin",
                        {
                            clientScreenHeight: window.innerHeight,
                            clientScreenWidth: window.innerWidth,
                        },
                    );
                } catch (error) {
                    this.$log(this.$options.name, "registerUserLogin", error, false);
                }
            },
        },
    };
</script>


<style lang="scss" scoped>
    @import "@/styles/helpers";

    .topBar {
        transition: background-image .5s, background-color .5s;
        border-bottom: var(--WDPanelHeading) solid 3px;

        &.atWorkdesk {
            background-color: var(--WDBannerTopColour) !important;
        }
        .schoolLogo {
            position: absolute;
            top: 0;
            left: 0;
            width: 350px;
            height: calc(100% - 3px);
            background-repeat: no-repeat;
            background-image: url("/Media/Load.ashx?Images/ModuleBanners/SIMON.gif");
            background-size: contain;
        }
    }
    .searchBar {
        width: 380px;
        padding-top: 25px !important;
    }
    .fixed-toolbar {
        position: fixed;
        left: 0;
        right: 0;
        z-index: 5;
    }
    .content_wrapper {
        background: var(--WDBackgroundGradientTop);
        background: linear-gradient(0deg, var(--WDBackgroundGradientBottom), var(--WDBackgroundGradientTop));
        height: 100vh;
        overflow: hidden;
        position: relative;

        .content {
            position: absolute;
            top: 40px;
            bottom: 0;
            left: 0;
            right: 0;
            overflow-y: auto;
            @include simon_scrollbar;
        }
    }
    .version-warning {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9999;
        padding-top: 40px;
        padding-bottom: 40px;
        opacity: .8;
    }
</style>
