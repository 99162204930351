<template>
    <router-link v-if="useRouter" :to="href">
        <slot />
    </router-link>
    <a v-else :href="href">
        <slot />
    </a>
</template>

<script>
    /**
     * Use this component to link to other pages.
     *
     * This component will automatically detect whether the provided href links to another vue page, and will use router navigation or page navigation accordingly.
     */
    export default {
        name: "SLink",
        props: {
            /**
             * The absolute or relative link to another page.
             */
            href: {
                type: String,
                required: true,
            },
        },
        computed: {
            useRouter() {
                return this.href && this.$router.isRouterLink(this.href);
            },
        },
    };
</script>
