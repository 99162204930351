export default [
    {
        path: '/daily-messages/',
        component: () => import(/* webpackChunkName: "daily-messages" */ "@/pages/daily_messages/DailyMessageDashboard"),
        alias: '/WebModules/DailyMessages/ViewDailyMessages.aspx',
        meta: {
            menu_id: "DAILYMESSAGES",
            menu_item_id: "DailyMessagesDashboard",
            title: "Daily Messages Dashboard",
        },
    },
    {
        path: '/daily-messages/categories/',
        component: () => import(/* webpackChunkName: "daily-messages" */ "@/pages/daily_messages/DailyMessageCategories"),
        alias: '/WebModules/DailyMessages/MessageCategories/MaintainDailyMessageCategories.aspx',
        meta: {
            menu_id: "DAILYMESSAGES",
            menu_item_id: "DailyMessagesMaintCategories",
            title: "Daily Message Categories",
        },
    },
    {
        path: '/daily-messages/configuration/',
        component: () => import(/* webpackChunkName: "daily-messages" */ "@/pages/daily_messages/DailyMessageConfiguration"),
        alias: '/WebModules/DailyMessages/DailyMessagesConfiguration.aspx',
        meta: {
            menu_id: "DAILYMESSAGES",
            menu_item_id: "DailyMessagesMaintConfig",
            title: "Daily Messages Configuration",
        },
    },
    {
        path: '/daily-messages/create/',
        component: () => import(/* webpackChunkName: "daily-messages" */ "@/pages/daily_messages/CreateDailyMessage"),
        alias: '/WebModules/DailyMessages/CreateDailyMessage.aspx',
        meta: {
            menu_id: "DAILYMESSAGES",
            menu_item_id: "DailyMessagesDashboard",
            title: "Create Daily Message",
        },
    },
    {
        path: '/daily-messages/edit/:id/',
        component: () => import(/* webpackChunkName: "daily-messages" */ "@/pages/daily_messages/CreateDailyMessage"),
        props: true,
        meta: {
            menu_id: "DAILYMESSAGES",
            menu_item_id: "DailyMessagesDashboard",
            title: "Edit Daily Message",
        },
    },
];
