import {
    faBell,
    faComment,
} from "@fortawesome/pro-solid-svg-icons";

import {
    faCloudSun as falCloudSun,
    faLocationDot,
} from "@fortawesome/pro-light-svg-icons";

// Menus
import CEOBResourceLinksIcon from "@/plugins/icons/custom/CEOBResourceLinksIcon";
import CommunicationCentreIcon from "@/plugins/icons/custom/CommunicationCentreIcon";
import ConnectSurveysIcon from "@/plugins/icons/custom/ConnectSurveysIcon";
import CurriculumAdminIcon from "@/plugins/icons/custom/CurriculumAdminIcon";
import CoCurricularActivitiesIcon from "@/plugins/icons/custom/CoCurricularActivitiesIcon";
import DailyMessagesIcon from "@/plugins/icons/custom/DailyMessagesIcon";
import DailyOrganisationIcon from "@/plugins/icons/custom/DailyOrganisationIcon";
import DataForStudentLearningIcon from "@/plugins/icons/custom/DataForStudentLearningIcon";
import DocumentManagementSystemIcon from "@/plugins/icons/custom/DocumentManagementSystemIcon";
import ESortIcon from "@/plugins/icons/custom/ESortIcon";
import ForumsIcon from "@/plugins/icons/custom/ForumsIcon";
import HazardIncidentRegisterIcon from "@/plugins/icons/custom/HazardIncidentRegisterIcon";
import KnowledgeBanksIcon from "@/plugins/icons/custom/KnowledgeBanksIcon";
import LearningAreasIcon from "@/plugins/icons/custom/LearningAreasIcon";
import LettersIcon from "@/plugins/icons/custom/LettersIcon";
import MedicalIcon from "@/plugins/icons/custom/MedicalIcon";
import ParentAccessIcon from "@/plugins/icons/custom/ParentAccessIcon";
import ParentTeacherInterviews from "@/plugins/icons/custom/ParentTeacherInterviewsIcon";
import ResourceBookingsIcon from "@/plugins/icons/custom/ResourcesBookingsIcon";
import RiskRegisterIcon from "@/plugins/icons/custom/RiskRegisterIcon";
import SchoolAdministrationIcon from "@/plugins/icons/custom/SchoolAdministrationIcon";
import SchoolCalendarIcon from "@/plugins/icons/custom/SchoolCalendarIcon";
import SiteAdministrationIcon from "@/plugins/icons/custom/SiteAdministrationIcon";
import SiteMapIcon from "@/plugins/icons/custom/SiteMapIcon";
import SocialBehaviourIcon from "@/plugins/icons/custom/SocialBehaviourIcon";
import StaffMessagesIcon from "@/plugins/icons/custom/StaffMessagesIcon";
import StaffProfilesIcon from "@/plugins/icons/custom/StaffProfilesIcon";
import StudentAssessmentIcon from "@/plugins/icons/custom/StudentAssessmentIcon";
import StudentAttendanceIcon from "@/plugins/icons/custom/StudentAttendanceIcon";
import StudentBooklistsIcon from "@/plugins/icons/custom/StudentBooklistsIcon";
import StudentProfilesIcon from "@/plugins/icons/custom/StudentProfilesIcon";
import SystemAdministrationIcon from "@/plugins/icons/custom/SystemAdministrationIcon";
import TimetablesIcon from "@/plugins/icons/custom/TimetablesIcon";
import WorkdeskIcon from "@/plugins/icons/custom/WorkdeskIcon";
import SchoolActivitiesIcon from "@/plugins/icons/custom/SchoolActivitiesIcon";
import {fa} from "@/plugins/icons/utils";

export default {
    // Misc
    alerts: fa(faBell),
    learningAreaAlerts: fa(faComment),
    weather: fa(falCloudSun),
    maps: fa(faLocationDot),

    // Menus
    ceobResourceLinks: {component: CEOBResourceLinksIcon},
    coCurricularActivitiesIcon: {component: CoCurricularActivitiesIcon},
    communicationCentre: {component: CommunicationCentreIcon},
    connectSurveys: {component: ConnectSurveysIcon},
    curriculumAdmin: {component: CurriculumAdminIcon},
    dailyMessages: {component: DailyMessagesIcon},
    dailyOrganisation: {component: DailyOrganisationIcon},
    dataForStudentLearning: {component: DataForStudentLearningIcon},
    documentManagementSystem: {component: DocumentManagementSystemIcon},
    eSort: {component: ESortIcon},
    forum: {component: ForumsIcon},
    hazardIncidentRegister: {component: HazardIncidentRegisterIcon},
    knowledgeBanks: {component: KnowledgeBanksIcon},
    learningAreas: {component: LearningAreasIcon},
    letters: {component: LettersIcon},
    medical: {component: MedicalIcon},
    parentAccess: {component: ParentAccessIcon},
    parentTeacherInterviews: {component: ParentTeacherInterviews},
    resourceBookings: {component: ResourceBookingsIcon},
    riskRegisterHome: {component: RiskRegisterIcon},
    schoolAdmin: {component: SchoolAdministrationIcon},
    schoolCalendars: {component: SchoolCalendarIcon},
    siteAdmin: {component: SiteAdministrationIcon},
    siteMap: {component: SiteMapIcon},
    socialBehaviour: {component: SocialBehaviourIcon},
    staffMessages: {component: StaffMessagesIcon},
    staffProfiles: {component: StaffProfilesIcon},
    studentAssessment: {component: StudentAssessmentIcon},
    studentAttendance: {component: StudentAttendanceIcon},
    studentBookLists: {component: StudentBooklistsIcon},
    studentProfiles: {component: StudentProfilesIcon},
    systemAdmin: {component: SystemAdministrationIcon},
    timetables: {component: TimetablesIcon},
    simonLogo: {component: WorkdeskIcon},
    // Modules
    schoolActivities: {component: SchoolActivitiesIcon},
};

export let menuIcons = {
    BEHAVIOURALTRACKING: "$socialBehaviour",
    CEOBRESOURCELINKS: "$ceobResourceLinks",
    COCURRICULAR: "$coCurricularActivitiesIcon",
    COMMCENTRE: "$communicationCentre",
    CONNECTSURVEYS: "$connectSurveys",
    CURRICULUMADMIN: "$curriculumAdmin",
    DAILYMESSAGES: "$dailyMessages",
    DAILYORGANISATION: "$dailyOrganisation",
    DOCUMENTMANAGEMENT: "$documentManagementSystem",
    DSL: "$dataForStudentLearning",
    FORUMS: "$forum",
    HAZARDINCIDENTREGISTER: "$hazardIncidentRegister",
    KNOWLEDGEBANKS: "$knowledgeBanks",
    LEARNINGAREAS: "$learningAreas",
    LETTERS: "$letters",
    MEDICAL: "$medical",
    PARENTACCESS: "$parentAccess",
    PARENTTEACHERINTERVIEWS: "$parentTeacherInterviews",
    RESOURCEBOOKINGS: "$resourceBookings",
    RISKREGISTERHOME: "$riskRegisterHome",
    SCHOOLADMIN: "$schoolAdmin",
    SCHOOLCALENDARS: "$schoolCalendars",
    SITEADMIN: "$siteAdmin",
    SITEMAP: "$siteMap",
    STAFFMESSAGES: "$staffMessages",
    STAFFPROFILES: "$staffProfiles",
    STUDENTASSESSMENT: "$studentAssessment",
    STUDENTATTENDANCE: "$studentAttendance",
    STUDENTBOOKLISTS: "$studentBookLists",
    STUDENTPROFILES: "$studentProfiles",
    SYSTEMADMIN: "$systemAdmin",
    TIMETABLES: "$timetables",
    WORKDESK: " ", // intentional space, to make string blank but not falsy.
    eSORT: "$eSort",
    default: "$link",
};
