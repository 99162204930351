import {componentMayPreventNavigation, disposeComponent} from "@/plugins/router";

// To use this mixin, make sure that preventNavigation is available on the component.
// It can be a data variable or a computed. When truthy, navigation will be prevented.

export default {
    beforeMount() {
        window.addEventListener("beforeunload", this._preventNavigation);
        componentMayPreventNavigation(this);
    },
    beforeDestroy() {
        window.removeEventListener("beforeunload", this._preventNavigation);
        disposeComponent(this);
    },
    // Note: The hook "beforeRouteLeave" provided by vue-router can only be used on the page level,
    // so that's why we're using our `componentMayPreventNavigation` instead.
    methods: {
        _preventNavigation(event) {
            // For navigating out of vue.
            if (this.preventNavigation) {
                event.preventDefault();
                event.returnValue = "";
            }
        },
    },
};
