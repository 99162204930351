export function useLightText(backgroundR, backgroundG, backgroundB) {
    return backgroundR + backgroundG + backgroundB < 350;
}

export function useLightTextRGB(rgb) {
    if (rgb.startsWith("#")) {
        rgb = rgb.slice(1);
    }
    if (rgb.length !== 6) {
        console.warn("Invalid colour", rgb);
        return false;
    }
    let r = rgb.slice(0, 2);
    let g = rgb.slice(2, 4);
    let b = rgb.slice(4, 6);
    const result = useLightText(
        parseInt(r, 16),
        parseInt(g, 16),
        parseInt(b, 16),
    );
    return result;
}
