import {getWebSocket} from "./socket";
import {snackbar} from "@/utils/generic_modals";

export let openClasses = 0;
let nextID = 1;

export default class BaseSocket {
    get ClassName() {
        throw new Error("NAME NOT SET");
    }
    constructor() {
        this.id = nextID++;
        this.callbacks = {};
        this.state = {};
        this.socket = getWebSocket(`${this.ClassName} (id: ${this.id})`);
        this.socket.on("connected", () => {
            this.socket.send("class", {
                id: this.id,
                name: this.ClassName,
                method: "RestoreState",
                args: [],
                state: this.state,
            }, true);
        });
        this.socket.on(`state:${this.id}`, data => {
            this.state = data;
        });
        this.socket.send("class", {
            id: this.id,
            name: this.ClassName,
            method: "Create",
            args: [],
        }, true);
        openClasses += 1;
    }
    on(eventName, callback) {
        this.socket.on(`class:${this.id}:${eventName}`, callback);
    }
    validateAndCallMethod(methodName, args, parameters) {
        const argumentsProvided = args.length;
        const requiredArguments = parameters.filter(x => x.required).length;
        if (argumentsProvided < requiredArguments) {
            const message = `${this.ClassName}.${methodName} requires ${requiredArguments} arguments.
${argumentsProvided} provided.`;
            snackbar.error(message);
            throw new Error(
                message,
            );
        }
        if (argumentsProvided > parameters.length) {
            const message = `${this.ClassName}.${methodName} only takes ${parameters.length} arguments.
${argumentsProvided} provided.`;
            snackbar.error(message);
            throw new Error(
                message,
            );
        }

        // check types?

        const paramsToUse = parameters.map(x => x.value);
        paramsToUse.length = argumentsProvided;
        this.callMethod(methodName, paramsToUse);
    }
    callMethod(method, args) {
        this.socket.send("class", {
            id: this.id,
            method,
            args,
        });
    }
    dispose() {
        openClasses -= 1;
        this.callMethod("Dispose", []);
        this.socket.dispose();
    }
}
