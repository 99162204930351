<template>
    <div>
        <div v-for="s in snackbars" :key="s.id">
            <v-snackbar
                v-model="s.open"
                :color="s.colour"
                top
            >
                {{ s.message }}
                <template #action>
                    <v-btn
                        icon
                        @click="s.open = false"
                    >
                        <v-icon>
                            $close
                        </v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </div>
</template>

<script>
    import {removeFromArray} from "@/utils/array";

    let snackbars = [];
    let id = 0;

    export function addSnackbar(message, colour) {
        let new_snackbar = {
            id: id++,
            message,
            colour,
            open: false,
        };

        for (let s of snackbars) {
            s.open = false;
            setTimeout(() => removeFromArray(s, snackbars), 1000);
        }
        snackbars.push(new_snackbar);
        setImmediate(() => {
            new_snackbar.open = true;
        });
    }

    export default {
        name: "GenericSnackbar",
        data() {
            return {
                snackbars,
            };
        },
    };
</script>
