import msgpack from "msgpack-lite";
import {removeFromArray} from "@/utils/array";
import store from "@/store";
import {confirmation} from "@/utils/generic_modals";
import codec from "./message_pack/codec";

export function getWebSocket(name) {
    return socket.getWrapper(name);
}


class SocketManager {
    constructor() {
        this.url = location.origin.replace("http", "ws") + "/Socket.ashx";
        this.wrappers = [];
        this.socket = null;
        this.wasConnected = false;
        this.createSocket();
        this.queuedMessages = [];
    }
    createSocket() {
        store.commit("setSocketConnectionState", null);
        if (typeof WebSocket === "undefined") {
            // This is executing in an environment that doesn't support websockets.
            // Most likely, the documentation.
            this.socket = null;
            return;
        }
        this.socket = new WebSocket(this.url);
        this.socket.onclose = () => {
            if (this.wasConnected) {
                this.sendEvent("disconnected");
            }
            this.wasConnected = false;
            this.socket = null;
            store.commit("setSocketConnectionState", false);
            setTimeout(() => this.createSocket(), 5000);
        };
        this.socket.onerror = error => console.warn("Websocket Error", error);
        this.socket.onmessage = event => {
            const reader = new FileReader();
            reader.addEventListener("loadend", () => {
                const decoded = msgpack.decode(new Uint8Array(reader.result), {codec});
                this.sendEvent(decoded.e, decoded.body);
            });
            reader.readAsArrayBuffer(event.data);
        };
        this.socket.onopen = () => {
            this.wasConnected = true;
            this.sendEvent("connected");
            store.commit("setSocketConnectionState", true);
            if (this.queuedMessages.length) {
                console.log("Sending queued messages");
                const messages = this.queuedMessages;
                this.queuedMessages = [];

                for (let message of messages) {
                    this.send(message);
                }
            }
        };
    }
    send(message, discard = false) {
        if (this.socket && this.wasConnected) {
            this.socket.send(message);
        } else if (discard) {
            console.warn("Message discarded - socket not connected");
        } else {
            console.warn("Message queued - socket not connected");
            this.queuedMessages.push(message);
        }
    }
    printMessageToConsole(name) {
        // queries messages happen very often - so lets keep the console cleaner.
        return store.state.canDebug && name !== "query";
    }
    sendEvent(name, body, specific) {
        const wrappers = (specific ? [specific] : this.wrappers);
        const sendDebug = this.printMessageToConsole(name);
        if (sendDebug) {
            this.sendEventDebug(wrappers, name, body);
        }

        for (let wrapper of wrappers) {
            const callbacks = wrapper.callbacks[name];
            if (callbacks && callbacks.length !== 0) {
                for (let callback of callbacks) {
                    try {
                        callback(body);
                    } catch (error) {
                        console.error("Couldn't run callback from", wrapper.name, callback, error);
                    }
                }
            }
        }
        if (sendDebug) {
            console.groupEnd();
        }
    }
    sendEventDebug(wrappers, name, body) {
        const names = wrappers.filter(x => x.callbacks[name] && x.callbacks[name].length).map(x => x.name);

        let extra = "";
        if (names.length === 0) {
            extra = " NO RECEIVERS!";
        }
        console.group(`%cbrowser < ${name}%c${extra}`, "color: green;", "color: red;");
        if (names.length !== 0) {
            console.log("Receivers:", names);
        }
        console.info(body);
    }
    getWrapper(name) {
        const wrapper = new SocketWrapper(name);
        this.wrappers.push(wrapper);
        return wrapper;
    }
    disposeWrapper(wrapper) {
        removeFromArray(wrapper, this.wrappers);
    }
}

class SocketWrapper {
    constructor(name) {
        this.name = name;
        this.callbacks = {};
    }
    on(eventName, callback) {
        if (Object.prototype.hasOwnProperty.call(this.callbacks, eventName)) {
            this.callbacks[eventName].push(callback);
        } else {
            this.callbacks[eventName] = [callback];
        }
    }
    off(eventName, callback) {
        removeFromArray(this.callbacks[eventName], callback);
    }
    init() {
        // Called after events are subscribed.
        if (socket.socket) {
            socket.sendEvent("connected", null, this);
        }
    }
    dispose() {
        // remove self from thingy, disable 'on' call.
        this.on = function () {
            console.error("Cannot subscribe on a disposed socket!");
        };
        socket.disposeWrapper(this);
    }
    send(method, body, discard = false) {
        if (!body) {
            body = {};
        }
        const methodName = method === "class" ? body.method : method;
        if (store.state.canDebug) {
            console.group(`%c${this.name} > ${methodName}`, "color: cyan;");
            console.info(...(method === "class" ? body.args : [body]));
            console.groupEnd();
        }
        socket.send(msgpack.encode({
            e: method,
            body,
        }, {codec}), discard);
    }
}


const socket = new SocketManager();
if (window.socket) {
    // Hot-reload fix.
    window.socket.dispose();
}
window.socket = socket.getWrapper("console");
window.socket.on("boom", body => {
    confirmation(
        null,
        "A critical error has occurred. Please inform support.\n\n"
            + body.message + "\n"
            + body.stack,
        true,
        "Critical Error",
    );
});
window.socket.on("version", body => {
    store.commit("setSocketSimonVersion", body);
});
