import {addConfirmationModal} from "@/components/application/modals/GenericConfirmation";

export async function confirmation(
    action = "Proceed",
    message = "Are you sure you want to perform this action?",
    dangerous = false,
    title = null,
) {
    return addConfirmationModal(title || `Confirmation required: ${action}?`, message, action, dangerous);
}


import {addSnackbar} from "@/components/application/modals/GenericSnackbar";

const acceptedColours = [
    null,
    "primary",
    "error",
    "warning",
    "success",
    "info",
];
export function snackbar(message, colour) {
    if (acceptedColours.indexOf(colour) === -1) {
        console.error("Unknown colour", colour, "used for snackbar. Defaulting to null.");
        colour = null;
    }
    addSnackbar(message, colour);
}

snackbar.primary = function (message) {
    snackbar(message, "primary");
};

snackbar.error = function (message) {
    snackbar(message, "error");
};

snackbar.warning = function (message) {
    snackbar(message, "warning");
};

snackbar.success = function (message) {
    snackbar(message, "success");
};

snackbar.info = function (message) {
    snackbar(message, "info");
};
