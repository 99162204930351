export default [
    {
        path: "/school-links/",
        alias: '/WebModules/SchoolLinks/SchoolLinks.aspx',
        component: () => import(/* webpackChunkName: "school-links" */ "@/pages/school-links/SchoolLinks"),
        meta: {
            menu_id: "WorkDesk",
            menu_item_id: "SchoolLinks",
        },
    },
];
