import {parseDateTime} from "@/utils/time";
import dayjs from "@/plugins/dayjs";


export default {
    sortAscending(array, condition) {
        return array.sort((a, b) => a[condition] - b[condition]);
    },
    sortDescending(array, condition) {
        return array.sort((a, b) => b[condition] - a[condition]);
    },
    formatDateTime(date) {
        if (date) {
            const epochRegex = /\/Date\((\d+)\)/;
            const match = date.match(epochRegex);
            if (!match) {
                return new Date(parseInt(date, 10));
            }
            return new Date(parseDateTime(date));
        }
    },
    dateString(date) {
        return date && dayjs(date).format("DD/MM/YYYY");
    },
    dateTimeString(date) {
        return date && dayjs(date).format("DD/MM/YYYY h:mm A");
    },
};
