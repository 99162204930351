export function syncProp(name = "value") {
    return {
        get() {
            return this[name];
        },
        set(value) {
            this.$emit(name === "value" ? "input" : `update:${name}`, value);
        },
    };
}
