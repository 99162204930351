import communication from "./communication";
import curriculum from "./curriculum";
import school from "./school";
import site from "./site";
import system from "./system";

export default [
    ...communication,
    ...curriculum,
    ...school,
    ...site,
    ...system,
];
