export default [
    {
        path: "/cocurricular/maintenance/categories/",
        component: () => import(/* webpackChunkName: "cocurricular" */ "@/pages/cocurricular/maintenance/Categories"),
        meta: {
            menu_id: "COCURRICULAR",
            menu_item_id: "CocurricularMaintCategories",
            title: 'Co-Curricular Activity Categories',
        },
    },
    {
        path: "/cocurricular/maintenance/categories/:categoryId/",
        component: () => import(/* webpackChunkName: "cocurricular" */ "@/pages/cocurricular/maintenance/CategoryActivities"),
        meta: {
            menu_id: "COCURRICULAR",
            menu_item_id: "CocurricularMaintCategories",
            title: 'Co-Curricular Activities',
        },
        props: true,
    },
    {
        path: "/cocurricular/maintenance/consent/",
        component: () => import(/* webpackChunkName: "cocurricular" */ "@/pages/cocurricular/maintenance/Consent"),
        meta: {
            menu_id: "COCURRICULAR",
            menu_item_id: "CocurricularMaintConsentDefault",
            title: 'Co-Curricular Consent',
        },
    },
];
