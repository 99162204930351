import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export function fa(icon, extra) {
    return {
        component: FontAwesomeIcon,
        props: {
            ...extra,
            icon,
        },
    };
}
