<template>
    <v-textarea
        ref="input"
        v-bind="$attrs"
        :rules="computedRules"
        :counter="maxLength"
        outlined
        dense
        v-on="$listeners"
    />
</template>

<script>
    import formMethods from "../forms/form_methods";
    import {required, maxLength} from "@/utils/form_validation";

    /**
     * This component wraps around [v-textarea](https://vuetifyjs.com/en/components/textareas/), mostly just to apply our stylings.
     */
    export default {
        name: "STextarea",
        mixins: [formMethods],
        props: {
            required: {
                type: Boolean,
            },
            /**
             Enforce a maximum length (by means of form validation) and also show a counter indicating this to the user.
             */
            maxLength: {
                type: Number,
                default: null,
            },
            rules: {
                type: Array,
                default: () => [],
            },
        },
        computed: {
            computedRules() {
                const rules = [...this.rules];
                if (this.required) {
                    rules.push(required());
                }
                if (this.maxLength) {
                    rules.push(maxLength(this.maxLength));
                }
                return rules;
            },
        },
    };
</script>
