function createForumPaths(basePath, type, menu_id = 'FORUMS') {
    const props = route => ({
        type: type.toUpperCase(),
        ...route.params,
    });
    return [
        {
            path: basePath + "forums/",
            component: () => import(/* webpackChunkName: "forums" */ "@/pages/forums/Forums"),
            meta: {
                menu_id,
                title: `${type} Forums`,
            },
            props,
        },
        {
            path: basePath + 'forums/:id/edit/',
            component: () => import(/* webpackChunkName: "forums" */ "@/pages/forums/ForumNew"),
            meta: {
                menu_id,
                title: 'Edit Forum',
            },
            props,
        },
        {
            path: basePath + 'forums/new/',
            component: () => import(/* webpackChunkName: "forums" */ "@/pages/forums/ForumNew"),
            meta: {
                menu_id,
                title: `Create ${type} Forum`,
            },
            props,
        },
        {
            path: basePath + 'forums/:id/new/',
            component: () => import(/* webpackChunkName: "forums" */ "@/pages/forums/ThreadNew"),
            meta: {
                menu_id,
                title: 'Create New Thread',
            },
            props,
        },
        {
            path: basePath + 'forums/:id/',
            component: () => import(/* webpackChunkName: "forums" */ "@/pages/forums/Thread"),
            meta: {
                menu_id,
                title: `${type} Forum`,
            },
            props: route => ({
                ...props(route),
                returnUrl: type === 'Subject' ? '../../class-forums/' : '../',
            }),
        },
    ];
}

export default [
    {
        path: "/WebModules/Forums/ForumsWorkDesk.aspx",
        redirect: "/forums/",
    },
    {
        path: "/WebModules/LearningAreas/Forums/ViewGeneralForums.aspx",
        redirect: "/forums/",
    },
    ...createForumPaths("/", "General"),
    ...createForumPaths("/learning/:targetId/:classId/subject-", "Subject", "LEARNINGAREAS"),
    ...createForumPaths("/learning/:subjectId/:targetId/class-", "Class", "LEARNINGAREAS"),
];
