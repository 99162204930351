<template>
    <v-form ref="form" v-bind="$attrs" v-model="innerValue" @submit.prevent.stop="submit">
        <!-- @slot Place all relevant form elements within the default slot. -->
        <slot />
    </v-form>
</template>

<script>
    import {syncProp} from "@/utils/component";
    import preventNavigation from "@/utils/mixins/prevent_navigation";

    /**
     * This is a wrapper for [v-form](https://vuetifyjs.com/en/api/v-form/), with some additional features.
     *
     * These features include:
     *
     * * Suppressing page submit by default (to avoid accidental browser navigation)
     * * Dirty prop, used to prevent router/page navigation when midway through a form.
     *
     * It should be used as the basis for data entry that requires validation, particularly beyond a couple of fields.
     * */
    export default {
        name: "SForm",
        mixins: [preventNavigation],
        props: {
            /**
             * @model
             * Immediately updated to reflect whether the form is currently valid.
             *
             * **Avoid using this to disable the submit button** - instead use the `validate()` method,
             * suppress the action if it returns false,
             * and let the form validations highlight the field that require attention.
             */
            value: {
                type: Boolean,
            },
            /**
             * When set, browser/router navigation will be blocked.
             * Set this when it might be bad for the user to simply navigate away.
             *
             * Note that this option won't inhibit the closure of modals, or other such controls that don't actually change the url.
             * However, setting the :persistant prop on a modal with the value used in the dirty flag is a good way to help prevent accidental closure.
             */
            dirty: {
                type: Boolean,
            },
        },
        computed: {
            innerValue: syncProp(),
            preventNavigation() {
                return this.dirty;
            },
        },
        methods: {
            /**
             * @public
             * Reset the form. Note: some custom controls don't play nicely with this yet, so you may need to clear their value manually for now
             * */
            reset() {
                this.$refs.form.reset();
            },
            /**
             * @public
             * Remove all validation warnings from fields.
             * Useful when closing a modal, so that when it's opened next time the validation messages aren't alreaddy there.
             */
            resetValidation() {
                this.$refs.form.resetValidation();
            },
            /**
             * @public
             * Returns true if the form is valid. Highlights form fields that aren't valid and returns false otherwise.
             * */
            validate() {
                return this.$refs.form.validate();
            },
            /**
             * @public
             * Manually submit the form.
             * */
            submit() {
                /** Emitted on submit - such as a submit button being pressed, or enter being pressed in certain fields (such as text-field) */
                this.$emit("submit");
            },
        },
    };
</script>
