export default [
    {
        path: '/community-login/maintain-accounts',
        component: () => import(/* webpackChunkName: "parent-login.maintain-accounts" */ "@/pages/parent-login/MaintainAccounts"),
        meta: {
            menu_id: 'ParentAccess',
            menu_item_id: 'CommunityLoginHome',
            title: 'Maintain Parent Login Accounts',
        },
    },
    {
        path: '/community-login/maintain-accounts/maintain-account',
        component: () => import(/* webpackChunkName: "parent-login.maintain-account" */ "@/pages/parent-login/MaintainAccount"),
        meta: {
            menu_id: 'ParentAccess',
            menu_item_id: 'CommunityLoginHome',
            title: 'Maintain Parent Login Account',
        },
    },
    {
        path: '/community-login/access-summary',
        component: () => import(/* webpackChunkName: "parent-login.maintain-account" */ "@/pages/parent-login/ParentAccessSummary"),
        meta: {
            menu_id: 'ParentAccess',
            menu_item_id: 'CommunityLoginHome',
            title: 'Parent Access Summary',
        },
    },
];
