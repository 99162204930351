import dayjs from "@/plugins/dayjs";

const dayjsDateFormat = "YYYYMMDDHHmmssSSS";

export function dayjsUnpacker(buffer) {
    const decoded = String.fromCharCode.apply(null, buffer);
    return Object.freeze(dayjs(decoded, dayjsDateFormat));
}

export function dayjsPacker(instance) {
    return Uint8Array.from(instance.format(dayjsDateFormat).split('').reduce(
        (acc, next) => [...acc, next.charCodeAt(0)],
        [],
    ));
}
