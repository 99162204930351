<template>
    <s-page>
        <v-row>
            <v-col>
                <v-alert type="error" icon="$debug404">
                    There is no page at {{ path }}
                </v-alert>
                Try searching for the page you were looking for, or returning to the workdesk.
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <search-bar />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn href="/" color="primary">
                    Return to the workdesk
                </v-btn>
            </v-col>
        </v-row>
    </s-page>
</template>

<script>
    import SearchBar from "@/components/application/SearchBar";

    export default {
        name: "PathNotFound",
        components: {
            SearchBar,
        },
        computed: {
            path() {
                return `${location.pathname}`;
            },
        },
        created() {
            this.$log("404", "Not Found", location.pathname);
        },
    };
</script>
