export default [
    {
        path: '/community-login/',
        component: () => import(/* webpackChunkName: "parent-login.dashboard" */ "@/pages/parent-login/Dashboard"),
        meta: {
            menu_id: 'ParentAccess',
            menu_item_id: 'CommunityLoginHome',
            title: 'Parent Access Work Desk',
        },
    },
    {
        path: '/community-login/create-bulk-accounts',
        component: () => import(/* webpackChunkName: "parent-login.create-bulk-account" */ "@/pages/parent-login/CreateBulkAccounts"),
        meta: {
            menu_id: 'ParentAccess',
            menu_item_id: 'CommunityLoginHome',
            title: 'Create Bulk Parent Login Accounts',
        },
    },
];
