<template>
    <s-modal
        v-model="innerValue"
        :valid="innerValid"
        :title="title"
        :loading="loading"
        persistent
        :confirm-text="confirmText"
        v-bind="$attrs"
        @confirm="confirm"
        @cancel="cancel"
    >
        <s-form ref="form" v-model="innerValid" :dirty="innerValue">
            <slot />
        </s-form>
    </s-modal>
</template>

<script>
    import {syncProp} from "@/utils/component";
    import {snackbar} from "@/utils/generic_modals";

    export default {
        name: "ModalForm",
        props: {
            /** @model
             * Controls whether the modal is open or closed. Use v-model.
             */
            value: {
                type: Boolean,
            },
            /** Title of the modal */
            title: {
                type: String,
                required: true,
            },
            /** Puts the modal into the loading state, which also blocks modal and form input. If not required, set this to false. */
            loading: {
                type: Boolean,
                required: true,
            },
            /** This prop can be synced to get the current validity status of the form. Note that setting this prop has no effect. */
            valid: {
                type: Boolean,
            },
            /** Text to display on the 'submit' button on the form.
             * If overriding this, it's best to keep the label as succinct as possible, such as "Add", "Save" or other single word descriptions.
             */
            confirmText: {
                type: String,
                default: "Confirm",
            },
        },
        data() {
            return {
                innerValid: false,
            };
        },
        computed: {
            innerValue: syncProp(),
        },
        watch: {
            innerValid: {
                immediate: true,
                handler(value) {
                    this.$emit("update:valid", value);
                },
            },
            value(value) {
                if (value) {
                    this.resetValidation();
                }
            },
        },
        methods: {
            /** Returns a boolean indicating whether the form is valid. If invalid, will light up fields that require validation.
             * When using @submit, this is called automatically so it should only be required if using @confirm. */
            validate() {
                return this.$refs.form.validate();
            },
            /** Reset the validation highlighting on the form elements.
             * This is automatically called when opening the modal, which should mean calling this manually shouldn't be required in most circumstances. */
            resetValidation() {
                this.$refs.form?.resetValidation();
            },
            confirm() {
                if (this.$listeners.confirm) {
                    /**
                     * Handle this event to take full control over the validation workflow of this modal form.
                     * This may be desirable if you have additional validation that needs to be done on top of the default field rules.
                     */
                    this.$emit('confirm');
                } else if (this.validate()) {
                    /**
                     * This event is called when the form submits the modal, and the field validation checks have passed.
                     * Remember that, even though validation is automatic, you still need to handle closing the modal.
                     */
                    this.$emit("submit");
                } else {
                    snackbar.warning("Please resolve issues in the highlighted fields and try again.");
                }
            },
            cancel() {
                if (this.$listeners.cancel) {
                    /** By default, the cancel action on the modal will simply close it.
                     * Use this even to override the cancel button's behaviour. */
                    this.$emit("cancel");
                } else {
                    this.innerValue = false;
                }
            },
        },
    };
</script>
