// https://vuetifyjs.com/en/customization/icons#reusable-custom-icons
// https://fontawesome.com/icons

/*
To add custom icons;
1. First, run the SVG through the optimiser at https://svgoptimizer.com to reduce the filesize.
2. Copy the SVG into a new vue component inside the template tag (a script or style tag is not necessary).
3. Add style="fill: currentColor" to the path tag(s) so that the icon inherits light/dark mode.
4. Import it, define it in the default section below then reference that in the menuIcons object below if relevant.
*/

import {fa} from "./utils";
import sim from "./sim";
import modules from "./modules";
import filetypes from "./filetypes";


import {
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faArrows,
    faArrowsAltV,
    faBars,
    faBell,
    faBomb,
    faBookmark,
    faBug,
    faCaretDown,
    faCaretRight,
    faCheckCircle,
    faCheckSquare,
    faCircle,
    faEnvelope,
    faEnvelopeOpen,
    faExclamationTriangle,
    faHome,
    faInfoCircle,
    faLink,
    faUnlink,
    faLinkSlash,
    faLock,
    faLockOpen,
    faMedkit,
    faMinusSquare,
    faPaperclip,
    faPersonSeat,
    faPlus,
    faSearch,
    faSortDown,
    faSortUp,
    faSpinnerThird,
    faStar,
    faStarHalf,
    faStepBackward,
    faStepForward,
    faTimes,
    faTimesCircle,
    faUserSecret,
    faClipboardList,
    faUndo,
    faChevronCircleRight,
    faChevronCircleLeft,
    faFiles,
    faUserGroup,
    faArrowTrendUp,
    faUserSlash,
    faFileCirclePlus,
    faFlag,
    faBullhorn,
    faMessageSms,
    faMessages,
    faMessageDots,
    faBooks,
    faIdCard,
    faCakeCandles,
    faEnvelopeOpenText,
    faBusSchool,
    faSquareInfo,
    faClipboardListCheck,
    faSchool,
    faCalendarLinesPen,
    faInboxFull,
    faChalkboardUser,
    faUser,
    faUsers,
    faGraduationCap,
    faEye,
    faScrewdriverWrench,
    faMessagesQuestion,
    faCalendarDays,
    faCalendarCircleUser,
    faCalendarCirclePlus,
    faFileImport,
    faDoorClosed,
    faDoorOpen,
    faCalendarStar,
    faCalendarXmark,
    faCalendarHeart,
    faCalendarExclamation,
    faCalendar,
    faCircleCalendar,
    faBookUser,
    faBookOpenCover,
    faBookArrowRight,
    faBracketsSquare,
    faArrowUpFromBracket,
    faBedBunk,
    faHouseChimneyHeart,
    faHouseTurret,
    faBuildingColumns,
    faUserCheck,
    faUserGraduate,
    faCalendarCircleMinus,
    faFileArrowUp,
    faBook,
    faHammer,
    faMessagePlus,
    faMessageCheck,
    faTrophy,
    faExclamation,
    faChartPie,
    faUpload,
    faAddressBook,
    faUsersGear,
} from "@fortawesome/pro-solid-svg-icons";

import {
    faCircle as farCircle,
    faDotCircle as farDotCircle,
    faEllipsisH as farEllipsisH,
    faEye as farEye,
    faEyeSlash as farEyeSlash,
    faSquare as farSquare,
    faStar as farStar,
    faNote as farNote,
    faRedo,
} from "@fortawesome/pro-regular-svg-icons";

import {
    faAngleDown as falAngleDown,
    faAngleUp as falAngleUp,
    faAt as falAt,
    faBan as falBan,
    faBookmark as falBookmark,
    faBooks as falBooks,
    faBroom as falBroom,
    faCalendar as falCalendar,
    faChalkboard as falChalkboard,
    faChalkboardTeacher as falChalkboardTeacher,
    faCheck as falCheck,
    faChevronLeft as falChevronLeft,
    faChevronRight as falChevronRight,
    faClock as falClock,
    faCog as falCog,
    faComment as falComment,
    faCompass as falCompass,
    faCompassSlash as falCompassSlash,
    faAddressCard as falAddressCard,
    faCopy as falCopy,
    faDatabase as falDatabase,
    faDownload as falDownload,
    faExternalLink as falExternalLink,
    faGraduationCap as falGraduationCap,
    faMars as falMars,
    faMinus as falMinus,
    faPaperPlane as falPaperPlane,
    faPaperclip as falPaperclip,
    faPencil as falPencil,
    faPencilRuler as falPencilRuler,
    faPlug as falPlug,
    faPlus as falPlus,
    faQuestion as falQuestion,
    faQuestionCircle as falQuestionCircle,
    faSatelliteDish as falSatelliteDish,
    faSave as falSave,
    faSignOut as falSignOut,
    faSkiJump as falSkiJump,
    faSms as falSms,
    faSync as falSync,
    faTimes as falTimes,
    faTrash as falTrash,
    faUser as falUser,
    faUsers as falUsers,
    faVenus as falVenus,
    faWarehouseAlt as falWarehouseAlt,
    faWindowMaximize as falWindowMaximize,
    faBookBlank as falBookBlank,
    faBookOpen as falBookOpen,
    faPrint as falPrint,
    faSwords as falSwords,
    faKey as falKey,
    faEraser as falEraser,
} from "@fortawesome/pro-light-svg-icons";
import {faBus} from "@fortawesome/pro-duotone-svg-icons";


export default {
    // Form actions
    loading: fa(faSpinnerThird, {spin: true}),
    reload: fa(falSync),
    alert: fa(faBell),
    alertExclamation: fa(faExclamationTriangle),
    message: fa(faEnvelope),
    add: fa(falPlus),
    minus: fa(falMinus),
    remove: fa(falTrash),
    edit: fa(falPencil),
    copy: fa(falCopy),
    download: fa(falDownload),
    calendar: fa(falCalendar),
    date: fa(falCalendar),
    clock: fa(falClock),
    time: fa(falClock),
    more: fa(farEllipsisH),
    hide: fa(farEyeSlash),
    show: fa(farEye),
    clear: fa(faTimes),

    // Item states
    locked: fa(faLock),
    unlocked: fa(faLockOpen),
    yes: fa(falCheck),
    no: fa(falTimes),
    messageRead: fa(faEnvelope),
    messageUnread: fa(faEnvelopeOpen),
    error: fa(faBomb),
    unknown: fa(falQuestion),
    unavailable: fa(falBan),

    clash: fa(falSwords),
    // School related
    class: fa(falWarehouseAlt),
    subject: fa(falChalkboard),
    assessment: fa(falBooks),
    classwork: fa(falPencilRuler),
    plan: fa(falChalkboardTeacher, {fixedWidth: true}),
    clipboardlist: fa(faClipboardList),
    seating: fa(faPersonSeat),

    // Application/window actions
    close: fa(faTimes),
    masquerade: fa(faUserSecret),
    home: fa(faHome),
    search: fa(faSearch),
    navigate: fa(falCompass),
    save: fa(falSave),
    link: fa(faLink),
    unlink: fa(faLinkSlash),
    external: fa(falExternalLink),
    maximise: fa(falWindowMaximize),
    menuDown: fa(faCaretDown, {transform: 'right-2 shrink-4'}),
    menuRight: fa(faCaretRight, {transform: 'right-4 shrink-4'}),
    help: fa(falQuestionCircle),
    exit: fa(falSignOut),
    next: fa(falChevronRight),
    prev: fa(falChevronLeft),
    previous: fa(falChevronLeft),
    settings: fa(falCog),
    quickLink: fa(falBookmark),
    quickLinkSelected: fa(falCheck, {
        mask: faBookmark,
        transform: 'shrink-6 up-1',
    }),

    // Tiered-fidelity-inventory
    flag: fa(faFlag),
    announcement: fa(faBullhorn),
    note: fa(farNote),
    openBook: fa(falBookOpen),
    closeBook: fa(falBookBlank),
    print: fa(falPrint),

    // Navbar
    employmentInfo: fa(falAddressCard),
    navbarCollapse: fa(faChevronCircleLeft),
    navbarOpen: fa(faChevronCircleRight),

    // Messaging
    email: fa(falAt),
    push: fa(falComment),
    sms: fa(falSms),
    send: fa(falPaperPlane),
    anonymous: fa(faUserSecret),
    attachment: fa(falPaperclip),
    authoriseMessage: fa(faMessageCheck),
    MessagePlus: fa(faMessagePlus),

    // Ordering
    up: fa(faSortUp),
    down: fa(faSortDown),
    arrowUp: fa(faArrowUp),
    arrowDown: fa(faArrowDown),
    arrowLeft: fa(faArrowLeft),
    arrowRight: fa(faArrowRight),
    expand: fa(falAngleDown),
    collapse: fa(falAngleUp),
    move: fa(faArrows),

    // People
    user: fa(falUser),
    student: fa(falUser),
    parent: fa(falUsers),
    staff: fa(falGraduationCap),
    male: fa(falMars, {fixedWidth: true}),
    female: fa(falVenus, {fixedWidth: true}),
    medicalInfo: fa(faMedkit),

    // Others required by Vuetify
    // List at https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/services/icons/presets/fa.ts
    complete: fa(falCheck),
    cancel: fa(faTimesCircle),
    delete: fa(faTimesCircle),
    success: fa(faCheckCircle),
    info: fa(faInfoCircle),
    warning: fa(faExclamationTriangle),
    checkboxOn: fa(faCheckSquare),
    checkboxOff: fa(farSquare),
    checkboxIndeterminate: fa(faMinusSquare),
    delimiter: fa(faCircle),
    sort: fa(faSortUp),
    menu: fa(faBars),
    subgroup: fa(faCaretDown),
    dropdown: fa(faCaretDown),
    radioOn: fa(farDotCircle),
    radioOff: fa(farCircle),
    ratingEmpty: fa(farStar),
    ratingFull: fa(faStar),
    ratingHalf: fa(faStarHalf),
    first: fa(faStepBackward),
    last: fa(faStepForward),
    unfold: fa(faArrowsAltV),
    file: fa(faPaperclip),
    plus: fa(faPlus),
    undo: fa(faUndo),
    key: fa(falKey),
    eraser: fa(falEraser),

    // Debugging
    debug: fa(faBug),
    debugJump: fa(falSkiJump),
    debug404: fa(falCompassSlash),
    debugCleanup: fa(falBroom),
    debugRequests: fa(falSatelliteDish),
    debugModels: fa(falDatabase),
    debugSocket: fa(falPlug),

    // enquiry
    Enquiries: fa(faFiles),
    Students: fa(faUserGroup),
    EnquiryProjection: fa(faArrowTrendUp),
    WithoutStudents: fa(faUserSlash),
    NewEnquiries: fa(faFileCirclePlus),


    // dashboard
    // School Administration
    SchoolAdminSetup: fa(faSchool),
    TimetableMaintenance: fa(faCalendarLinesPen),
    SubjectClasses: fa(faChalkboardUser),
    DailyOrganisation: fa(faInboxFull),
    Student: fa(faUser),
    ParentAccess: fa(faUsers),
    Staff: fa(faGraduationCap),

    // School Setup
    SchoolAdminTermDates: fa(faCalendarDays),
    SchoolAdminCampus: fa(faBuildingColumns),
    SchoolAdminYearLevel: fa(faUserGraduate),
    SchoolAdminHouse: fa(faHouseTurret),
    SchoolAdminHomeroom: fa(faHouseChimneyHeart),
    SchoolAdminDomainComponent: fa(faUserCheck),
    SchoolAdminSchools: fa(faSchool),
    SchoolAdminGlobalGroups: fa(faUserGroup),
    SchoolAdminBoardingHouse: fa(faBedBunk),

    // Timetable Maintenance
    TimetableMaintenanceImportExport: fa(faFileArrowUp),
    TimetableMaintenanceBase: fa(faCalendarDays),
    TimetableMaintenanceClassPreferences: fa(faCalendarHeart),
    TimetableMaintenanceAdditional: fa(faCalendarCirclePlus),
    TimetableMaintenanceGroups: fa(faUserGroup),
    TimetableMaintenanceEvents: fa(faCalendarStar),
    TimetableMaintenanceNonTeachingDates: fa(faCalendarCircleMinus),
    TimetableMaintenanceAccess: fa(faCalendarCircleUser),
    TimetableMaintenanceBuilder: fa(faHammer),

    // Timetable Maintenance Submenu
    TimetableMaintenanceImport: fa(faFileImport),
    TimetableMaintenanceUnavailabilities: fa(faCalendarXmark),
    TimetableMaintenanceDefinition: fa(faCalendarLinesPen),
    TimetableMaintenanceRooms: fa(faSchool),
    TimetableMaintenanceUnavailabilitiesBase: fa(faCalendar),
    TimetableMaintenanceUnavailabilitiesFinalised: fa(faScrewdriverWrench),
    TimetableMaintenanceUnavailabilitiesTypes: fa(faCircleCalendar),
    TimetableMaintenanceDefinitionBase: fa(faCalendar),
    TimetableDefinitionOverrideMaintain: fa(faScrewdriverWrench),
    TimetableDefinitionAdHocTemplates: fa(faCalendarExclamation),
    TimetableMaintenanceRoomsTypes: fa(faDoorClosed),
    TimetableMaintenanceRoomsSchoolRooms: fa(faDoorOpen),

    // Timetable Maintenance SubSubmenu
    TimetableMaintenanceImportBaseTimetable: fa(faFileImport),
    TimetableMaintenanceImportStudentClasses: fa(faCalendarCircleUser),
    TimetableMaintenanceImportTTUnavailabilities: fa(faCalendarXmark),
    TimetableMaintenanceImportRoster: fa(faCalendarDays),
    TimetableMaintenanceImportSubjects: fa(faArrowUpFromBracket),

    // Subjects and Classes
    SubjectClassesSubjects: fa(faBookOpenCover),
    SubjectClassesClasses: fa(faChalkboardUser),
    SubjectClassesStudents: fa(faBookUser),
    SubjectClassesMigrateSubject: fa(faBookArrowRight),
    SubjectClassesSuffixes: fa(faBracketsSquare),

    // Staff
    CommunicationCentre: fa(faMessagesQuestion),
    StaffKnowledgeBases: fa(faBook),

    // Staff Submenu
    StaffKnowledgeBasesView: fa(faEye),
    StaffKnowledgeBasesMaintain: fa(faScrewdriverWrench),

    // Student
    SocialBehaviour: fa(faChartPie),
    recurring: fa(faRedo),
    Activities: fa(faBus),
    Suspensions: fa(faUnlink),
    Detentions: fa(faLink),
    Incidents: fa(faExclamation),
    Commendations: fa(faTrophy),
    StaffBehavtrack: fa(faClipboardListCheck),
    SchoolAdminMedical: fa(faMedkit),
    StudentLetters: fa(faEnvelopeOpenText),
    StudentTransportManagement: fa(faBusSchool),
    Booklists: fa(faBooks),
    StudentLibraryCards: fa(faIdCard),
    StudentBirthdaylisting: fa(faCakeCandles),
    StudentKiosk: fa(faSquareInfo),
    StudentSMS: fa(faMessageSms),

    // Student Submenu
    StudentSMSSend: fa(faMessageSms),
    StudentSMSGroups: fa(faMessages),
    StudentSMSConfig: fa(faMessageDots),

    // Community Login Dashboard
    Upload: fa(faUpload),
    AddressBook: fa(faAddressBook),
    UsersGear: fa(faUsersGear),

    ...filetypes,
    ...modules,
    ...sim,
};
