export default [
    {
        path: '/studentattendance/maintenance/irregular-absence-notifications',
        component: () => import(/* webpackChunkName: "studentattendance" */ "@/pages/studentattendance/maintenance/IrregularAbsenceNotifications"),
        meta: {
            menu_id: "STUDENTATTENDANCE",
            title: "Irregular Absence Notifications",
        },
    },
];
