<template functional>
    <froala-view
        :value="props.value"
        v-bind="attrs"
        v-on="listeners"
    />
</template>

<script>
    /**
     Use this component to safely display HTML content to the user.
    */
    export default {
        name: "SHtml",
        props: {
            /**
             * Raw HTML string
             */
            value: {
                type: String,
                default: null,
            },
        },
    };
</script>
