export default [
    {
        path: '/staff-messages/',
        component: () => import(/* webpackChunkName: "staff-messages" */ "@/pages/staff_messages/StaffMessageDashboard"),
        alias: '/WebModules/StaffMessages/ViewStaffMessages.aspx',
        meta: {
            menu_id: "STAFFMESSAGES",
            menu_item_id: "StaffMessagesDashboard",
            title: "Staff Messages Dashboard",
        },
    },
    {
        path: '/staff-messages/categories/',
        component: () => import(/* webpackChunkName: "staff-messages" */ "@/pages/staff_messages/StaffMessageCategories"),
        alias: '/WebModules/StaffMessages/MessageCategories/MaintainStaffMessageCategories.aspx',
        meta: {
            menu_id: "STAFFMESSAGES",
            menu_item_id: "StaffMessagesMaintCategories",
            title: "Staff Message Categories",
        },
    },
    {
        path: '/staff-messages/configuration/',
        component: () => import(/* webpackChunkName: "staff-messages" */ "@/pages/staff_messages/StaffMessageConfiguration"),
        alias: '/WebModules/StaffMessages/StaffMessagesConfiguration.aspx',
        meta: {
            menu_id: "STAFFMESSAGES",
            menu_item_id: "StaffMessagesMaintConfig",
            title: "Staff Messages Configuration",
        },
    },
    {
        path: '/staff-messages/create/',
        component: () => import(/* webpackChunkName: "staff-messages" */ "@/pages/staff_messages/CreateStaffMessage"),
        alias: '/WebModules/StaffMessages/CreateStaffMessage.aspx',
        meta: {
            menu_id: "STAFFMESSAGES",
            menu_item_id: "StaffMessagesDashboard",
            title: "Create Staff Message",
        },
    },
    {
        path: '/staff-messages/edit/:id/',
        component: () => import(/* webpackChunkName: "staff-messages" */ "@/pages/staff_messages/CreateStaffMessage"),
        props: true,
        meta: {
            menu_id: "STAFFMESSAGES",
            menu_item_id: "StaffMessagesDashboard",
            title: "Edit Staff Message",
        },
    },
];
