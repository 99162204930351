export default [
    {
        path: '/profiles/students/contacts/',
        component: () => import(/* webpackChunkName: "admin.profiles" */ "@/pages/profiles/students/contacts/Contacts"),
        meta: {menu_id: 'STUDENTPROFILES'},
    },
    {
        path: '/profiles/students/student_contacts/',
        component: () => import(/* webpackChunkName: "admin.profiles" */ "@/pages/profiles/students/contacts/StudentContacts"),
        meta: {menu_id: 'STUDENTPROFILES'},
    },
];
