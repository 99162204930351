<template>
    <v-select v-bind="$attrs" ref="input" outlined dense v-on="$listeners" />
</template>

<script>
    import formMethods from "../forms/form_methods";
    /**
     * This component wraps around [v-select](https://vuetifyjs.com/en/components/selects/), mostly just to apply our stylings.
     */
    export default {
        name: "SSelect",
        mixins: [formMethods],
    };
</script>
