<template>
    <v-combobox
        v-if="allowUnlistedValue"
        v-bind="$attrs"
        ref="input"
        outlined
        :rules="calculatedRules"
        :counter="calculatedCounter"
        :return-object="returnObject"
        dense
        v-on="$listeners"
    >
        <!-- pass through scoped slots -->
        <template v-for="(_, scopedSlotName) in $scopedSlots" #[scopedSlotName]="slotData">
            <slot :name="scopedSlotName" v-bind="slotData" />
        </template>

        <!-- pass through normal slots -->
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-combobox>
    <v-autocomplete
        v-else
        ref="input"
        v-bind="$attrs"
        outlined
        auto-select-first
        :rules="calculatedRules"
        :return-object="returnObject"
        dense
        v-on="$listeners"
    >
        <!-- pass through scoped slots -->
        <template v-for="(_, scopedSlotName) in $scopedSlots" #[scopedSlotName]="slotData">
            <slot :name="scopedSlotName" v-bind="slotData" />
        </template>

        <!-- pass through normal slots -->
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-autocomplete>
</template>

<script>
    /*
    This component combines both the v-autocomplete and v-combobox components.
    By default, it'll act as a v-autocomplete. This means that the value selected must be present in the supplied list.

    When allowUnlistedValue is true, v-combobox will be used instead which allows arbitrary text as valid input,
    but still giving people the option to pick from the list if they desire.
     */
    import formMethods from "@/components/common/forms/form_methods";
    import {required, maxLength} from "@/utils/form_validation";

    export default {
        name: "Autocomplete",
        mixins: [formMethods],
        props: {
            /**
             When true, allow arbitrary text. When false, the selection is limited to the list.
            */
            allowUnlistedValue: {
                type: Boolean,
            },
            /**
             * When true, a form validation rule will be injected that ensures the value is set to something.
             */
            required: {
                type: Boolean,
            },
            /**
             * An array of rules for validation. See Vuetify documentation for further details.
             */
            rules: {
                type: Array,
                default: () => [],
            },
            /**
             * Provide a field definition to automatically set some of the validation rules.
             */
            field: {
                type: Object,
                default: null,
            },
            /**
             Returns the full object when true, rather than just the value. Note: This will still return just a string when a value absent from the list is selected.
            */
            returnObject: {
                type: Boolean,
            },
            /**
             * Override the max value used for the counter. This will implicitly add a validation rule.
             */
            counter: {
                type: Number,
                default: null,
            },
        },
        computed: {
            calculatedRules() {
                const rules = [...this.rules];
                if (this.required) {
                    rules.push(required());
                }
                if (this.calculatedCounter) {
                    rules.push(maxLength(this.calculatedCounter));
                }
                return rules;
            },
            calculatedCounter() {
                if (this.counter !== null) {
                    return this.counter;
                }
                if (this.field) {
                    return this.field.maxLength;
                }
                return null;
            },
        },
    };
</script>
