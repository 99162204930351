import axios from "axios";
import {reactive} from "vue";
import store from "@/store";
import {snackbar} from "@/utils/generic_modals";
import {uncompressDictionaries} from "./requests.helpers";
export {uncompressDictionaries};
import {userGlobal} from "@/plugins/user";


export const postJSONAsyncCallbacks = [];

function callback(id, info) {
    for (let func of postJSONAsyncCallbacks) {
        func(id, info);
    }
}

let callID = 0;

function cleanURL(url) {
    if (!url.startsWith("/")) {
        return "/WebServices/" + url;
    }
    return url;
}

async function handleError(error) {
    if (error.response && error.response.status === 401) {
        snackbar.error("Your session is not authenticated.");
        if ((await axios.get("/WebServicesAPI/AuthType.ashx")).data === "FORMS") {
            location.href = "/Login/Default.aspx?ReturnUrl=" + encodeURIComponent(location.pathname);
        }
    }
}

let mockedRequests = null;

export async function mockPostAsync(responses = null) {
    /**
    Provide a dictionary of {url: response}.

     Set either directly to an object, or to a function which takes data as an attribute, which returns the response.

    Eg:
    {
        "/hello-world": "Hi",
        "/bye-world": (data) => "unusual, but ok",
    }

     Set to null to clear, and start doing real requests again.
     */
    mockedRequests = responses;
}

function finalProcessing(response) {
    let isJSON = response.headers["content-type"].indexOf("application/json") === 0;
    if (isJSON && response.data.d && response.data.d._type === "cd") {
        return uncompressDictionaries(response.data.d);
    }
    return isJSON ? response.data.d : response.data;
}

export async function postAsync(url, data = {}, config = null) {
    if (mockedRequests !== null) {
        if (url in mockedRequests) {
            if (typeof(mockedRequests[url]) === "function") {
                return mockedRequests[url](data);
            }
            return mockedRequests[url];
        }
        throw new Error("Requests is mocked, but the endpoint " + url + " is not expected");
    }
    const id = callID++;
    const info = reactive({
        id,
        started: new Date(),
        state: "Started",
        data,
        body: "",
        url,
        sql: null,
    });
    callback(id, info);
    url = cleanURL(url);

    const options = {
        headers: {
            "Content-Type": data instanceof FormData ? 'multipart/form-data' : "application/json",
            "X-CSRF": userGlobal.loaded ? userGlobal.getConfig("csrf") : null,
        },
        ...(config || {}),
    };

    if (store.state.canDebug) {
        options.headers.ShowDebugInformation = "1";
    }

    info.state = "Calling";
    let response;
    try {
        store.commit("startRequest");
        response = await axios.post(url, data, options);
        info.state = response.statusText;
        info.body = response.data;
        info.sql = response.headers.sql;
    } catch (error) {
        info.state = error.message;
        info.body = error.response.data;
        await handleError(error);
        throw error;
    } finally {
        info.finished = new Date();
        store.commit("finishRequest");
    }
    return finalProcessing(response);
}

const cache = {};
const cacheTimeout = 1000 * 10;

export async function cachedPostAsync(url, data = {}) {
    url = cleanURL(url);
    const key = JSON.stringify({
        url,
        data,
    });

    const id = callID++;

    if (key in cache) {
        cache[key].info += 1;
        return finalProcessing(await cache[key].responseAsync);
    }

    const info = {
        id,
        started: new Date(),
        state: "Started",
        data,
        body: "",
        url,
        sql: null,
    };
    callback(id, info);

    try {
        store.commit("startRequest");
        const responseAsync = axios.post(url, data, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "X-CSRF": userGlobal.loaded ? userGlobal.getConfig("csrf") : null,
            },
        });
        cache[key] = {
            responseAsync,
            info,
        };
        const response = await responseAsync;
        setTimeout(() => delete cache[key], cacheTimeout); // allow reuse within the cache timeout period
        info.state = response.statusText;
        info.body = response.data;
        info.sql = response.headers.sql;
        info.used = 1;
        return finalProcessing(response);
    } catch (error) {
        delete cache[key]; // retry immediately, instead of keeping the failed request
        info.state = error.message;
        info.body = error.response.data;
        await handleError(error);
        throw error;
    } finally {
        info.finished = new Date();
        store.commit("finishRequest");
    }
}
