export default [
    {
        path: '/studentassessment/exports/exportTJAS',
        component: () => import(/* webpackChunkName: "studentassessment" */ "@/pages/studentassessment/exports/ExportTJAS"),
        meta: {
            menu_id: "STUDENTASSESSMENT",
            title: "TJAS Export",
        },
    },
];
