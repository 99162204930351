import dayjs from "@/plugins/dayjs";
import {parseDate} from "@/utils/time";

export function maxLength(count) {
    return function (value) {
        if (!value || value.length <= count) {
            return true;
        }
        return `Limit of ${count} characters`;
    };
}

export function required(text = "Value is required") {
    return function (value) {
        if (value === 0) {
            return true;
        }
        if (typeof value === "string") {
            value = value.trim();
        }
        return !!value || text;
    };
}

export function numeric() {
    return function (value) {
        return !isNaN(value) || "Must be a number";
    };
}

export function unique(array) {
    return function (value) {
        if (value === null) {
            return true;
        }
        return !array.includes(value) || "Must be unique";
    };
}

export function integer() {
    return function (value) {
        return !!/^\d*$/.exec(value) || "Must be an integer";
    };
}

export function email() {
    return function (value) {
        if (!value) {
            return true;
        }
        const match = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.exec(value);
        return !!match || "Must be an email address";
    };
}

export function phone() {
    return function (value) {
        if (!value) {
            return true;
        }
        return !!/^\+? ?\d[\d ]*$/.exec(value) || "Must be a phone number";
    };
}

export function past(date) {
    const dayjsVersion = parseDate(date);
    if (!date || dayjs().isAfter(dayjsVersion) || dayjs().isSame(dayjsVersion, 'date')) {
        return true;
    }
    return "Enter a valid date";
}
export function future(date) {
    const dayjsVersion = parseDate(date);
    if (!date || dayjs().isBefore(dayjsVersion) || dayjs().isSame(dayjsVersion, 'date')) {
        return true;
    }
    return "Enter a valid date";
}
