import Vue from 'vue';
import store from "../store";
import {confirmation, snackbar} from "@/utils/generic_modals";
import {removeFromArray} from "@/utils/array";
import VueRouter from 'vue-router';
Vue.use(VueRouter);

import routes from "@/router";

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes,
});

export let lastUrl = null;

const navigationPreventingComponents = [];
export function componentMayPreventNavigation(component) {
    navigationPreventingComponents.push(component);
}

export function disposeComponent(component) {
    removeFromArray(component, navigationPreventingComponents);
}

router.beforeEach(async (to, from, next) => {
    for (const component of navigationPreventingComponents) {
        if (component.preventNavigation) {
            let confirmed = await confirmation(
                "Discard Changes",
                "You may have unsaved changes that will be lost if you leave this page.",
                true,
            );
            if (!confirmed) {
                // Prevent navigation.
                return next(false);
            }
            break; // Only ask once, even if multiple components are trying to prevent navigation.
        }
    }
    lastUrl = location.href;
    if (to.matched[0] !== from.matched[0]) {
        // Only send global loading event if parent route changes
        // This is to prevent odd transitions in the parent component when only the inner route changes.
        store.commit("startGlobalLoading");
    }
    next();
});

router.afterEach((to, from) => {
    if (to.matched[0] !== from.matched[0]) {
        store.commit("endGlobalLoading");
    }
});

router.onError(failure => {
    snackbar.error("The page failed to load.");
    store.commit("endGlobalLoading");
    console.error(failure);
});

router.isRouterLink = function (url) {
    if (!url || url.indexOf(":") !== -1) {
        // Urls with a colon blow up getMatchedComponents. Let's assume they are not one of ours.
        return false;
    }
    const component = router.getMatchedComponents(url);
    return component.length !== 0 && !component[0] || component[0].name !== "PathNotFound";
};

export default router;
