export default [
    {
        path: '/social-behaviour/tiered-fidelity-inventory/',
        component: () => import(/* webpackChunkName: "social-behaviour-tfi.dashboard" */ "@/pages/social-behaviour/tiered-fidelity-inventory/Dashboard"),
        meta: {
            menu_id: 'BehaviouralTracking',
            menu_item_id: 'BehavTrackTFIAssessment',
        },
    },
    {
        path: '/social-behaviour/tiered-fidelity-inventory/history',
        component: () => import(/* webpackChunkName: "social-behaviour-tfi.submission-history" */ "@/pages/social-behaviour/tiered-fidelity-inventory/SubmissionHistory"),
        meta: {
            menu_id: 'BehaviouralTracking',
            menu_item_id: 'BehavTrackTFIAssessment',
        },
    },
    {
        path: '/social-behaviour/tiered-fidelity-inventory/:assessment/preview/',
        component: () => import(/* webpackChunkName: "social-behaviour-tfi.assessment-preview" */ "@/pages/social-behaviour/tiered-fidelity-inventory/AssessmentPreview"),
        meta: {
            menu_id: 'BehaviouralTracking',
            menu_item_id: 'BehavTrackTFIAssessment',
        },
    },
    {
        path: '/social-behaviour/tiered-fidelity-inventory/:assessment/',
        alias: ['/social-behaviour/tiered-fidelity-inventory/:assessment/edit', '/social-behaviour/tiered-fidelity-inventory/:assessment/view/'],
        component: () => import(/* webpackChunkName: "social-behaviour-tfi.assessment-scoring" */ "@/pages/social-behaviour/tiered-fidelity-inventory/ScoringPage"),
        meta: {
            menu_id: 'BehaviouralTracking',
            menu_item_id: 'BehavTrackTFIAssessment',
        },
    },
    {
        path: '/social-behaviour/tiered-fidelity-inventory/:assessment/summary-report/',
        component: () => import(/* webpackChunkName: "social-behaviour-tfi.summary-report" */ "@/pages/social-behaviour/tiered-fidelity-inventory/SummaryReport"),
        meta: {
            menu_id: 'BehaviouralTracking',
            menu_item_id: 'BehavTrackTFIAssessment',
        },
    },
];
