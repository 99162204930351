<template>
    <v-dialog
        v-model="innerValue"
        v-resize="resize"
        :persistent="modalPersistent"
        :max-width="modalMaxWidth"
        :fullscreen="useFullscreen"
        :eager="eager"
    >
        <div v-if="useFullscreen" class="overlay" />
        <panel
            :title="title"
            :loading="loading"
            :error="error"
            :text-max-height="textHeight"
            :tile="useFullscreen"
        >
            <template
                v-for="(_, name) in $scopedSlots"
                :slot="name"
                slot-scope="slotData"
            >
                <slot :name="name" v-bind="slotData" />
            </template>

            <template v-if="showCloseIcon === null ? !persistent : showCloseIcon" #title.append>
                <div>
                    <slot name="title.append" />
                    <s-btn icon="$close" icon-style @click="cancel" />
                </div>
            </template>

            <template v-if="error" #actions>
                <v-spacer />
                <v-btn
                    color="primary"
                    text
                    @click="innerValue = false"
                >
                    Close
                </v-btn>
            </template>

            <template v-else #actions>
                <slot name="actions.cancel">
                    <s-btn
                        type="cancel"
                        @click="cancel"
                    >
                        {{ cancelText }}
                    </s-btn>
                </slot>
                <v-spacer />
                <slot name="actions.steppers">
                    <s-btn
                        v-if="showSteppers"
                        class="hidden-xs-only"
                        :disabled="previousDisabled"
                        @click="previous"
                    >
                        <v-icon left>
                            $previous
                        </v-icon>
                        Previous
                    </s-btn>
                    <s-btn
                        v-if="showSteppers"
                        class="hidden-xs-only"
                        :disabled="nextDisabled"
                        @click="next"
                    >
                        Next
                        <v-icon right>
                            $next
                        </v-icon>
                    </s-btn>
                    <s-btn
                        v-if="showSteppers"
                        class="hidden-sm-and-up"
                        text
                        :disabled="previousDisabled"
                        @click="previous"
                    >
                        <v-icon>$previous</v-icon>
                    </s-btn>
                    <s-btn
                        v-if="showSteppers"
                        class="hidden-sm-and-up"
                        text
                        :disabled="nextDisabled"
                        @click="next"
                    >
                        <v-icon>$next</v-icon>
                    </s-btn>
                </slot>
                <v-spacer class="hidden-sm-and-up" />
                <slot name="actions" :valid="valid">
                    <s-btn
                        v-if="!hideConfirm"
                        type="confirm"
                        :color="confirmColour"
                        @click="confirm"
                    >
                        {{ confirmText }}
                    </s-btn>
                </slot>
            </template>
        </panel>
    </v-dialog>
</template>

<script>
    import Panel from "@/components/common/Panel";

    /**
     * Modal, with stepper support.
     * For basic modals, see the generic_modals.js file instead.
     *
     * ** Remember, when showing save notifications and the like, use a snackbar instead.**
     */
    export default {
        name: "Modal",
        components: {
            Panel,
        },
        props: {
            /**
             * @model
             When `true`, the modal is displayed, and it is hidden when `false`. Use this instead of `v-if` for example,
             for animation reasons.
            */
            value: {
                type: Boolean,
            },
            /**
             [As per panel](#panel)
            */
            title: {
                type: String,
                required: true,
            },
            /**
             [As per panel](#panel)
            */
            loading: {
                type: Boolean,
            },
            /**
             [As per panel](#panel)
            */
            error: {
                type: [Error, String],
                default: null,
            },
            /**
             When true, clicking outside of the modal will cause a "don't do that" animation.
             When left false, this will set `value` to false, thus dismissing the modal.
            */
            persistent: {
                type: Boolean,
            },
            /**
             Normally, the content of the modal is only mounted when required.
             Settings this will mount it immediately instead.
            */
            eager: {
                type: Boolean,
            },
            /**
             Shows a close icon in the titlebar of the modal - similar to your average windows dialogue.
             `null` - close icon only when modal is not persistent. Clicking the icon fires the cancel event.
            */
            showCloseIcon: {
                type: [Boolean, null],
                default: null,
            },
            /**
             When `null`, the modal will automatically switch to fullscreen according to device size.
             Otherwise, this allows you to force the modal to either be or not be full screen.
            */
            fullscreen: {
                type: [Boolean, null],
                // `null`
                default: null,
            },
            /**
             Limits the max width of the modal much more than otherwise.
            */
            small: {
                type: Boolean,
            },
            /**
             Makes the steppers in the modal visible and usable. See the events listed below.
            */
            showSteppers: {
                type: Boolean,
            },
            /**
             Disable the previous stepper (only useful with showSteppers enabled)
            */
            previousDisabled: {
                type: Boolean,
            },
            /**
             Disable the next stepper (only useful with showSteppers enabled)
            */
            nextDisabled: {
                type: Boolean,
            },
            /**
             Override the cancel text on the modal.
            */
            cancelText: {
                type: String,
                default: "Cancel",
            },
            /**
             Hide confirmation button. Can be useful for notification popups.
            */
            hideConfirm: {
                type: Boolean,
            },
            /**
             Override the "confirm" text on the modal.
            */
            confirmText: {
                type: String,
                default: "Confirm",
            },
            /** When set, show confirmation button a nice red to warn the user the action they're confirming is destructive */
            confirmDangerous: {
                type: Boolean,
            },
            /**
              * Set the "confirm" button to non-primary while this is false.
              * Remember that this will still be called, so your confirm handler must check this.
              */
            valid: {
                type: Boolean,
                default: true, // eslint-disable-line vue/no-boolean-default
            },
            /**
             Override the max width of the modal. Generally it's better to leave this alone and use the `small` prop
             if required.
            */
            maxWidth: {
                type: [String, Number],
                default: 1000,
            },
        },
        data() {
            return {
                textHeight: this.calculateTextHeight(),
            };
        },
        computed: {
            innerValue: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit("input", value);
                },
            },
            useFullscreen() {
                if (this.fullscreen === null) {
                    return !this.small && this.$vuetify.breakpoint.xs;
                }
                return this.fullscreen;
            },
            modalMaxWidth() {
                return this.small ? "500px" : this.maxWidth;
            },
            modalPersistent() {
                return this.loading || this.persistent;
            },
            confirmColour() {
                if (!this.valid) {
                    return null;
                }
                if (this.confirmDangerous) {
                    return "error";
                }
                return "primary";
            },
        },
        methods: {
            calculateTextHeight() {
                const total = (
                    42 // top
                    + 2 // line
                    + 16 * 2 // padding
                    + 53 // bottom
                );
                let modalHeight = window.innerHeight;
                if (modalHeight > 1000) {
                    modalHeight = 1000;
                }
                const multiplier = this.useFullscreen ? 1 : .85;
                return parseInt((modalHeight - total) * multiplier, 10);
            },
            resize() {
                this.textHeight = this.calculateTextHeight();
            },
            next() {
                /**
                 Emitted when the 'next' stepper has been pressed.
                */
                this.$emit('next');
            },
            previous() {
                /**
                 Emitted when the 'previous' stepper has been pressed.
                */
                this.$emit('previous');
            },
            confirm() {
                /**
                 Emitted when the 'confirm' button has been pressed.
                */
                this.$emit('confirm');
            },
            cancel() {
                /**
                 Emitted when the 'cancel' button has been pressed.
                */
                this.$emit('cancel');
            },
        },
    };
</script>

<style scoped>
    .overlay {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: rgba(33, 33, 33, .46);
    }
</style>
