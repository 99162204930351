import {userGlobal} from "@/plugins/user";

export default [
    {
        path: "/jump/timetable/",
        beforeEnter() {
            window.location.href = userGlobal.isStaff ? "/WebModules/Timetables/StaffTimetable.aspx" : "/WebModules/Timetables/StudentTimetable.aspx";
        },
    },
];
