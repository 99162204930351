<template>
    <v-text-field
        ref="input"
        v-model="innerValue"
        v-bind="$attrs"
        outlined
        dense
        :rules="activeRules"
        :counter="maxLength"
        autocomplete="off"
        v-on="$listeners"
    >
        <slot
            v-for="(_, slot) in $slots"
            :slot="slot"
            :name="slot"
        />

        <template
            v-for="(_, slot) in $scopedSlots"
            :slot="slot"
            slot-scope="scope"
        >
            <slot :name="slot" v-bind="scope" />
        </template>
    </v-text-field>
</template>

<script>
    import formMethods from "../forms/form_methods";
    import {
        required,
        maxLength,
        email,
        phone,
        integer,
        numeric,
    } from "@/utils/form_validation";

    let rule_definitions = {
        required,
        maxLength,
        email,
        phone,
        integer,
        numeric,
    };

    /**
     * This component wraps [v-text-field](https://vuetifyjs.com/en/components/textfields/) mostly to apply our stylings.
     *
     * It also has various helpers to make validation easier to set up.
     */

    export default {
        name: "TextField",
        mixins: [formMethods],
        props: {
            /**
             * @model
             * The value in the field
             */
            value: {
                type: String,
                default: null,
            },
            /**
             * Add 'required' validation to this field.
             */
            required: {
                type: [Boolean, String],
                default: false,
            },
            /**
             Enforce a maximum length (by means of form validation) and also show a counter indicating this to the user.
            */
            maxLength: {
                type: Number,
                default: null,
            },
            /**
             * Force all content in this field to be uppercased, such as for codes.
             */
            uppercase: {
                type: Boolean,
            },
            /**
             * Add a validation rule that enforces something that could conceivably be an email address.
             */
            email: {
                type: Boolean,
            },
            /**
             * Add a validation rule that enforces something that could conceivably be a phone number.
             */
            phone: {
                type: Boolean,
            },
            /**
             * Make this field only accept integers (including negative).
             */
            integer: {
                type: Boolean,
            },
            /**
             * Make this field only accept numerics (including negative, expansions and decimals)
             */
            numeric: {
                type: Boolean,
            },
            /**
             * Add extra rules not covered by other props.
             */
            rules: {
                type: Array,
                default: () => [],
            },
        },
        computed: {
            innerValue: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit("input", value);
                },
            },
            activeRules() {
                let rules = [...this.rules];
                for (let key of Object.keys(rule_definitions)) {
                    if (this[key] === true) {
                        rules.push(rule_definitions[key]());
                    } else if (this[key]) {
                        rules.push(rule_definitions[key](this[key]));
                    }
                }
                return rules;
            },
        },
        watch: {
            innerValue(value) {
                if (!value || !this.uppercase) {
                    return;
                }
                const caps = value.toUpperCase();
                if (value !== caps) {
                    this.innerValue = caps;
                }
            },
        },
    };
</script>
