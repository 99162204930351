<template>
    <s-page title="Access Denied" class="text-center">
        <font-awesome-icon :icon="faDoNotEnter" class="error--text" size="5x" />
        <br />
        <br />
        You do not have access to this page. If you think you should, please speak to your SIMON Administrator.
        <br />
        This event has been logged.
        <br />
        <br />
        <s-btn href="/" color="primary">
            Return to workdesk
        </s-btn>
    </s-page>
</template>


<script>
    import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
    import {faDoNotEnter} from "@fortawesome/pro-solid-svg-icons";

    export default {
        name: 'AccessDenied',
        components: {
            FontAwesomeIcon,
        },
        data() {
            return {
                faDoNotEnter,
            };
        },
        mounted() {
            // In the case where somebody tries to access a student profile sub-menu item (ie. Student Notes) and they don't have access, they could
            // get in a continuous loop where they can no longer view any student profile sub-menu page so we need to reset the last page visited in
            // storage to NULL so the app does not continually try to push them back to the same page in error over and over.
            delete localStorage.lastStudentProfilesPage;
        },
    };
</script>
