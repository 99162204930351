<template>
    <v-menu offset-y left>
        <template #activator="{on}">
            <v-btn icon v-on="on">
                <v-badge :color="loading ? 'primary' : 'error'" :value="loading || count" overlap>
                    <template v-if="loading" #badge>
                        <v-icon x-small>
                            $loading
                        </v-icon>
                    </template>
                    <template v-else #badge>
                        {{ count }}
                    </template>
                    <v-icon>
                        {{ icon }}
                    </v-icon>
                </v-badge>
            </v-btn>
        </template>
        <v-list>
            <s-list-item
                v-for="notification in notifications"
                :key="`${notification.CategoryID}.${notification.AlertID}`"
                :href="notification.UrlLink"
                :data-url="notification.UrlLink"
                :data-alert="notification.AlertID"
                v-on="alertEvents"
            >
                <v-list-item-icon>
                    <v-icon v-if="messages" large>
                        $message
                    </v-icon>
                    <v-icon v-else-if="notification.Notification" large>
                        $alerts
                    </v-icon>
                    <v-icon v-else large>
                        $alertExclamation
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content class="notification">
                    <div class="category">
                        {{ notification.CategoryTitle }}
                    </div>
                    <div v-if="notification.AlertCounter" class="alert-title">
                        {{ notification.AlertCounter }} {{ notification.AlertTitle }}
                    </div>
                    <div v-else class="alert-title">
                        {{ notification.AlertTitle }}
                    </div>
                </v-list-item-content>
            </s-list-item>
            <v-list-item
                v-if="!notifications.length"
            >
                <v-list-item-title v-if="isNaN(count)">
                    {{ messages ? 'Messages' : 'Notifications' }} failed to load.
                    <v-btn small @click="updateAlerts">
                        Retry
                    </v-btn>
                </v-list-item-title>
                <v-list-item-title v-else>
                    There are currently no {{ messages ? 'messages' : 'notifications' }}.
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
    import {postAsync} from "@/utils/requests";
    import {Alerts, Messages} from "@/plugins/socket_classes";

    export default {
        name: "Alerts",
        props: {
            messages: {
                type: Boolean,
            },
            alertEvents: {
                type: Object,
                default: () => {},
            },
        },
        data() {
            return {
                count: "?",
                notifications: [],
                loading: true,
                interval: null,
                timeout: null,
            };
        },
        computed: {
            url() {
                return this.messages ? "Default.asmx/GetMessages" : "Default.asmx/GetAlerts";
            },
            icon() {
                return this.messages ? "$learningAreaAlerts" : "$alerts";
            },
        },
        watch: {
            url() {
                this.updateAlerts();
            },
            messages: {
                immediate: true,
                handler(messages) {
                    this.alerts?.dispose();
                    this.alerts = messages ? new Messages() : new Alerts();
                    this.alerts.socket.on("disconnected", () => this.startPolling());
                    this.alerts.socket.on("connected", () => {
                        clearInterval(this.interval);
                        clearTimeout(this.timeout);
                    });
                    this.alerts.on("alerts", data => {
                        this.count = data.AlertCount;
                        let results = [];
                        for (let category of data.Categories) {
                            for (let alert of category.Alerts) {
                                results.push({
                                    CategoryID: category.CategoryID,
                                    CategoryTitle: category.CategoryTitle,
                                    ...alert,
                                });
                            }
                        }
                        this.notifications = results;
                        this.loading = false;
                    });
                },
            },
        },
        created() {
            this.timeout = setTimeout(() => {
                // Give the websocket a second to connect. If it doesn't, fall back to ajax.
                this.startPolling();
                this.updateAlerts();
            }, 1000);

            this.loading = true;
        },
        destroyed() {
            this.alerts.dispose();
            clearInterval(this.interval);
            clearTimeout(this.timeout);
        },
        methods: {
            startPolling() {
                if (this.$store.state.canDebug) {
                    // Don't fall back in debug mode, as whenever the websocket is down it'll be due to code changes.
                    return;
                }
                this.interval = setInterval(this.updateAlerts, 2 * 60 * 1000);
            },
            async updateAlerts() {
                let alerts;
                try {
                    alerts = await postAsync(this.url);
                } catch (e) {
                    this.loading = false;
                    return;
                }
                this.count = alerts.AlertCount;
                let results = [];
                for (let category of alerts.Categories) {
                    for (let alert of category.Alerts) {
                        results.push({
                            CategoryID: category.CategoryID,
                            CategoryTitle: category.CategoryTitle,
                            ...alert,
                        });
                    }
                }
                this.notifications = results;
                this.loading = false;
            },
        },
    };
</script>

<style scoped lang="scss">
    .notification {
        position: relative;

        .category {
            font-weight: bold;
        }
    }
</style>
