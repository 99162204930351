<template>
    <v-navigation-drawer
        v-model="drawer"
        app
        right
        class="draw"
        width="300px"
        temporary
        dark
    >
        <template #prepend>
            <v-btn text icon class="closeButton" @click="drawer = false">
                <v-icon>
                    $close
                </v-icon>
            </v-btn>
            <div class="userSection">
                <div class="userSectionIcon">
                    <div
                        class="userImage"
                        :style="{
                            'background-image': `url('/WebHandlers/DisplayUserPhoto.ashx?GUID=${$user.guid}')`
                        }"
                    />
                </div>
                <div class="userSectionInfo">
                    <span class="username">
                        {{ $user.masquerade ? `"${$user.name}"` : $user.name }}
                    </span>
                    <div class="userOptions">
                        <s-btn
                            v-for="menu in userMenu"
                            :key="menu.MenuItemID"
                            :href="menu.NavigateUrl"
                            :target="menu.Target"
                            text
                            x-small
                        >
                            {{ menu.Caption.replace('My ', '') }}
                        </s-btn>
                        <small v-if="$user.masquerade">(Logged in as {{ $user.masquerade }})</small>
                        <s-btn
                            v-if="$user.masquerade"
                            color="red"
                            href="/WebModules/Masquerade/CeaseMasquerade.aspx"
                            icon="$masquerade"
                            x-small
                        >
                            Cease Masquerading
                        </s-btn>
                        <s-btn
                            v-else-if="$user.canMasquerade"
                            href="/WebModules/Masquerade/Masquerade.aspx"
                            icon="$masquerade"
                            small
                        >
                            Masquerade As...
                        </s-btn>
                    </div>
                </div>
            </div>
            <br />
            <div class="ie-fix" />
        </template>

        <main>
            <div class="searchBar hidden-md-and-up">
                <br />
                <search-bar />
            </div>
            <v-list dense>
                <s-list-item
                    v-for="(item, i) in navItems"
                    :key="item ? item.MenuItemID : i"
                    :href="item && item.NavigateUrl"
                    :target="item && item.Target"
                >
                    <v-row v-if="item">
                        <v-col>
                            {{ item.Caption }}
                        </v-col>
                        <v-col class="flex-grow-0">
                            <v-icon
                                v-if="!item.Target || item.Target !== '_self'"
                                title="This link will open in a new tab"
                                x-small
                            >
                                $external
                            </v-icon>
                        </v-col>
                    </v-row>
                    <v-divider v-else class="menu-separator" />
                </s-list-item>
            </v-list>
            <div v-if="navItems.length === 1" class="text-center">
                Navigation is not currently available.
            </div>
        </main>

        <template #append>
            <div>
                <div class="bottomButtons">
                    <s-btn v-if="$user.isStaff" text href="https://simon-knowledge-hub.refined.site/" target="_blank" icon="$help">
                        Help
                    </s-btn>
                    <s-btn v-if="$user.getConfig('ShowLogout')" text href="/Logout.aspx" icon="$exit">
                        Log out
                    </s-btn>
                </div>
                <a class="simonInfo" target="_blank" href="https://simonschools.net/en-au/about-simon/">
                    <div>software information</div>
                    <div>© 1998 - {{ currentYear }} - {{ version }}</div>
                </a>
            </div>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import SearchBar from "./SearchBar";
    import {cmpFunction} from "@/utils/array";

    export default {
        name: "SideNav",
        components: {
            SearchBar,
        },
        props: {
            version: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                drawer: false,
            };
        },
        computed: {
            cisMenu() {
                return this.$store.state.websiteMenuItems.filter(x => x.MenuID.toLowerCase() === "cismenu");
            },
            userMenu() {
                const userMenu = this.cisMenu.filter(x => x.ParentMenuID === "UserMenu");
                userMenu.sort(cmpFunction(x => x.Caption));
                return userMenu;
            },
            navItems() {
                let items = [];
                let group = null;
                const excluded = [
                    "UserMenu",
                    "Help",
                    "",
                ];

                for (let item of this.cisMenu.filter(x => excluded.indexOf(x.ParentMenuID) === -1)) {
                    if (group !== item.MenuGroup) {
                        if (group !== null) {
                            items.push(null);
                        }
                        group = item.MenuGroup;
                    }
                    if (item.NavigateUrl) {
                        items.push(item);
                    }
                }
                items.push(null);
                items.push(...this.cisMenu.filter(x => x.Caption === "Sitemap"));
                return items;
            },
            currentYear() {
                return this.$store.state.now.year();
            },
        },
        watch: {
            drawer: {
                handler() {
                    document.body.classList[this.drawer ? "add" : "remove"]("drawerOpen");
                },
                immediate: true,
            },
        },
        methods: {
            toggle() {
                this.drawer = !this.drawer;
            },
        },
    };
</script>


<style scoped lang="scss">
    @import "@/styles/helpers";

    .draw {
        background-color: var(--WDPanelHeading) !important;
        background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, .2));

        :deep(.v-navigation-drawer__content) {
            @include simon_scrollbar;
            mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
            padding-bottom: 30%;
        }
    }
    .closeButton {
        float: right;
        margin-bottom: -1em;
    }
    .bottomButtons {
        display: flex;
        justify-content: space-around;
    }
    .simonInfo {
        font-size: .8em;
        text-align: center;
        color: rgba(255, 255, 255, .3);
        text-decoration: none;
    }
    .userSection {
        width: 100%;
        float: left;
        color: #fff;
        padding: 0 30px 5px;
        box-sizing: border-box;
        display: flex;
        margin-bottom: 10px;

        .userImage {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            height: 70px;
            width: 70px;
            border-radius: 50%;
        }
        .userOptions {
            text-align: center;
        }
        .username {
            font-size: 20px;
            line-height: 1.1;
            text-align: center;
        }
        .userSectionIcon {
            width: 38%;
            float: left;
            font-size: 40px;
        }
        .userSectionInfo {
            width: 62%;
            float: left;
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
    }
    .menu-separator {
        border-color: var(--WDUnderline) !important;
    }
    .searchBar {
        padding-left: 12px;
    }
</style>
