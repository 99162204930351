<template>
    <panel
        v-if="noContainer"
        v-resize="resize"
        v-test:page
        class="panel-margin"
        :title="panelTitle"
        :loading="loading"
        :error="error"
        :background-colour="backgroundColour"
        full-height
    >
        <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
            <slot :name="slot" v-bind="scope" />
        </template>

        <template #title.append>
            <slot name="title.append" />
            <sim v-if="$store.state.simEverywhere" />
        </template>
        <slot />
    </panel>
    <panel
        v-else
        v-resize="resize"
        v-test:page
        class="panel-margin"
        :title="panelTitle"
        :loading="loading"
        :error="error"
        full-height
    >
        <v-container>
            <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
                <slot v-if="slot !== 'title'" :name="slot" v-bind="scope" />
            </template>
            <slot />
        </v-container>

        <template #title.append>
            <sim v-if="$store.state.simEverywhere" />
            <slot name="title.append" />
        </template>

        <template #error>
            <slot name="error" />
        </template>
    </panel>
</template>

<script>
    import Panel from "@/components/common/Panel";

    /**
     Template for single-panel pages.
     This should be the root element of almost all templates in the pages folder.
    */
    export default {
        name: 'Page',
        components: {
            Panel,
            Sim: () => import(/* webpackChunkName: "workdesk" */ "@/components/application/Sim"),
        },
        inject: ["pageTitleWithoutVersion"],
        props: {
            /**
             [As per panel](#panel)
            */
            title: {
                type: String,
                // Use the router's title field, or failing that, guess based on the URL.
                default: null,
            },
            /**
             [As per panel](#panel)
             */
            loading: {
                type: Boolean,
            },
            /**
             [As per panel](#panel)
             */
            error: {
                type: [Error, String],
                default: null,
            },
            /**
             Renders the content directly into the page, instead of inside a v-container.
             This might be required for full-width content.
            */
            noContainer: {
                type: Boolean,
            },
            /**
             Override the background of the content area.
            */
            backgroundColour: {
                type: String,
                default: null,
            },
        },
        data() {
            return {
                minHeight: this.calculateMinHeight(),
            };
        },
        computed: {
            panelTitle() {
                return this.title || this.pageTitleWithoutVersion.title;
            },
        },
        methods: {
            calculateMinHeight() {
                return window.innerHeight - 120;
            },
            resize() {
                this.minHeight = this.calculateMinHeight();
            },
        },
    };
</script>

<style scoped lang="scss">
    @import "@/styles/variables";

    .panel-margin {
        margin-top: 8px;
        margin-right: 8px;
        margin-left: 8px;
        margin-bottom: 8px;
        overflow-x: hidden;
    }
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
        .panel-margin {
            margin: 0;
        }
    }
</style>
