import Empty from "@/pages/admin/Empty";
import actions from "./actions";

export default [
    ...actions,
    {
        path: '/admin/site/',
        component: Empty,
        alias: '/WebModules/SiteAdministration/SiteAdministration.aspx',
        meta: {menu_id: 'SITEADMIN'},
    },
    {
        path: '/admin/site/eduhub/',
        alias: '/WebModules/SiteAdministration/Integrations/EduHubConfiguration.aspx',
        component: () => import(/* webpackChunkName: "admin.site" */ "@/pages/admin/site/EduHub"),
        meta: {
            menu_id: "SITEADMIN",
            title: "EduHub v1 Configuration",
        },
    },
    {
        path: '/admin/site/logging-configuration/',
        component: () => import(/* webpackChunkName: "admin.site" */ "@/pages/admin/site/LoggingConfiguration"),
        alias: '/WebModules/SiteAdministration/MaintainLoggingConfiguration.aspx',
        meta: {
            menu_id: "SITEADMIN",
            title: "Maintain Logging Configuration",
        },
    },
    {
        path: '/admin/site/wonde/',
        component: () => import(/* webpackChunkName: "admin.site" */ "@/pages/admin/site/Wonde"),
        meta: {
            menu_id: "SITEADMIN",
            title: "Wonde Configuration",
        },
    },
    {
        path: '/admin/site/adminsync/',
        component: () => import(/* webpackChunkName: "admin.site" */ "@/pages/admin/site/AdminSync"),
        meta: {
            menu_id: "SITEADMIN",
            title: "Admin Package Sync",
        },
    },
    {
        path: '/admin/site/adminsync/providerconfig',
        component: () => import(/* webpackChunkName: "admin.site" */ "@/pages/admin/site/AdminSyncProviderConfig"),
        meta: {
            menu_id: "SITEADMIN",
            title: "Admin Package Sync Provider Configuration",
        },
    },
    {
        path: '/admin/site/staffstatus',
        alias: '/WebModules/SiteAdministration/StaffStatus/MaintainStaffStatus.aspx',
        component: () => import(/* webpackChunkName: "admin.site" */ "@/pages/admin/site/StaffStatus"),
        meta: {
            menu_id: "SITEADMIN",
            title: "Maintain Staff Status",
        },
    },
    {
        path: '/admin/site/studentstatus',
        alias: '/WebModules/SiteAdministration/StudentStatus/MaintainStudentStatus.aspx',
        component: () => import(/* webpackChunkName: "admin.site" */ "@/pages/admin/site/StudentStatus"),
        meta: {
            menu_id: "SITEADMIN",
            title: "Maintain Student Status",
            menu_item_id: 'SystemSetupStudentStatus',
        },
    },
    {
        path: '/admin/site/addressfinder/',
        component: () => import(/* webpackChunkName: "admin.site" */ "@/pages/admin/site/AddressFinderConfiguration"),
        meta: {
            menu_id: "SITEADMIN",
            title: "Address Finder Configuration",
        },
    },
];
