import Vuex from "vuex";

const store = new Vuex.Store({
    state: {
        guid: null,
        title: null,
        modal: false,
    },
    mutations: {
        update(state, payload) {
            state[payload.key] = payload.value;
        },
    },
});

export default store;
