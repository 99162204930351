export default [
    {
        path: '/communication/',
        component: () => import(/* webpackChunkName: "communication" */ "@/pages/communication/Communication"),
        alias: '/WebModules/Communication/Centre.aspx',
        meta: {menu_id: 'COMMCENTRE'},
    },
    {
        path: '/communication/:id/',
        component: () => import(/* webpackChunkName: "communication" */ "@/pages/communication/Message"),
        meta: {
            menu_id: 'COMMCENTRE',
            title: "Communication - Message",
        },
        props: true,
    },
];
