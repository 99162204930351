// DO NOT EDIT THIS FILE! - It is generated by JSGenerator.
/* eslint-disable max-len */
import BaseSocket from "./socket_class_base";

export class Forum extends BaseSocket {
    get ClassName() {
        return "Simon.WebSocket.Forums.Forum";
    }
    CreateForum(title, start, end, anonymous, access, filters, classId) {
        this.validateAndCallMethod("CreateForum", arguments, [
            {
                name: "title",
                type: "String",
                required: true,
                value: title,
            },
            {
                name: "start",
                type: "String",
                required: true,
                value: start,
            },
            {
                name: "end",
                type: "String",
                required: true,
                value: end,
            },
            {
                name: "anonymous",
                type: "Boolean",
                required: true,
                value: anonymous,
            },
            {
                name: "access",
                type: "String",
                required: true,
                value: access,
            },
            {
                name: "filters",
                type: "Dictionary`2",
                required: true,
                value: filters,
            },
            {
                name: "classId",
                type: "Int32",
                required: true,
                value: classId,
            },
        ]);
    }
    DeleteForum(id, classId) {
        this.validateAndCallMethod("DeleteForum", arguments, [
            {
                name: "id",
                type: "Int32",
                required: true,
                value: id,
            },
            {
                name: "classId",
                type: "Int32",
                required: true,
                value: classId,
            },
        ]);
    }
    EditForum(id, title, start, end, anonymous, access, filters, classId) {
        this.validateAndCallMethod("EditForum", arguments, [
            {
                name: "id",
                type: "Int32",
                required: true,
                value: id,
            },
            {
                name: "title",
                type: "String",
                required: true,
                value: title,
            },
            {
                name: "start",
                type: "String",
                required: true,
                value: start,
            },
            {
                name: "end",
                type: "String",
                required: true,
                value: end,
            },
            {
                name: "anonymous",
                type: "Boolean",
                required: true,
                value: anonymous,
            },
            {
                name: "access",
                type: "String",
                required: true,
                value: access,
            },
            {
                name: "filters",
                type: "Dictionary`2",
                required: true,
                value: filters,
            },
            {
                name: "classId",
                type: "Int32",
                required: true,
                value: classId,
            },
        ]);
    }
    GetFiltersMatrixForForum(id) {
        this.validateAndCallMethod("GetFiltersMatrixForForum", arguments, [
            {
                name: "id",
                type: "Int32",
                required: true,
                value: id,
            },
        ]);
    }
    NewForum(type, targetId) {
        this.validateAndCallMethod("NewForum", arguments, [
            {
                name: "type",
                type: "String",
                required: true,
                value: type,
            },
            {
                name: "targetId",
                type: "Int32",
                required: false,
                value: targetId,
            },
        ]);
    }
    OpenForums(type, targetId) {
        this.validateAndCallMethod("OpenForums", arguments, [
            {
                name: "type",
                type: "String",
                required: true,
                value: type,
            },
            {
                name: "targetId",
                type: "Int32",
                required: false,
                value: targetId,
            },
        ]);
    }
}

export class ForumPosts extends BaseSocket {
    get ClassName() {
        return "Simon.WebSocket.Forums.ForumPosts";
    }
    AddMessage(message, anonymous) {
        this.validateAndCallMethod("AddMessage", arguments, [
            {
                name: "message",
                type: "String",
                required: true,
                value: message,
            },
            {
                name: "anonymous",
                type: "Boolean",
                required: false,
                value: anonymous,
            },
        ]);
    }
    DeletePost(id) {
        this.validateAndCallMethod("DeletePost", arguments, [
            {
                name: "id",
                type: "Int32",
                required: true,
                value: id,
            },
        ]);
    }
    DeleteThread(classId) {
        this.validateAndCallMethod("DeleteThread", arguments, [
            {
                name: "classId",
                type: "Int32",
                required: true,
                value: classId,
            },
        ]);
    }
    EditMessage(id, message) {
        this.validateAndCallMethod("EditMessage", arguments, [
            {
                name: "id",
                type: "Int32",
                required: true,
                value: id,
            },
            {
                name: "message",
                type: "String",
                required: true,
                value: message,
            },
        ]);
    }
    OpenThread(id) {
        this.validateAndCallMethod("OpenThread", arguments, [
            {
                name: "id",
                type: "Int32",
                required: true,
                value: id,
            },
        ]);
    }
}

export class ForumThreads extends BaseSocket {
    get ClassName() {
        return "Simon.WebSocket.Forums.ForumThreads";
    }
    CreateThread(title, firstPost) {
        this.validateAndCallMethod("CreateThread", arguments, [
            {
                name: "title",
                type: "String",
                required: true,
                value: title,
            },
            {
                name: "firstPost",
                type: "String",
                required: true,
                value: firstPost,
            },
        ]);
    }
    OpenForum(id, showThreads) {
        this.validateAndCallMethod("OpenForum", arguments, [
            {
                name: "id",
                type: "Int32",
                required: true,
                value: id,
            },
            {
                name: "showThreads",
                type: "Boolean",
                required: false,
                value: showThreads,
            },
        ]);
    }
    RenameThread(title, threadId) {
        this.validateAndCallMethod("RenameThread", arguments, [
            {
                name: "title",
                type: "String",
                required: true,
                value: title,
            },
            {
                name: "threadId",
                type: "Int32",
                required: true,
                value: threadId,
            },
        ]);
    }
}

export class ProfileImage extends BaseSocket {
    get ClassName() {
        return "Simon.WebSocket.Socket.Common.ProfileImage";
    }
    WatchForCommunityID(communityId) {
        this.validateAndCallMethod("WatchForCommunityID", arguments, [
            {
                name: "communityId",
                type: "Int32",
                required: true,
                value: communityId,
            },
        ]);
    }
}

export class Manager extends BaseSocket {
    get ClassName() {
        return "Simon.WebSocket.Socket.Models.Manager";
    }
    Sub(models) {
        this.validateAndCallMethod("Sub", arguments, [
            {
                name: "models",
                type: "Dictionary`2",
                required: true,
                value: models,
            },
        ]);
    }
    Unsub(models) {
        this.validateAndCallMethod("Unsub", arguments, [
            {
                name: "models",
                type: "Dictionary`2",
                required: true,
                value: models,
            },
        ]);
    }
    ViewSubs() {
        this.validateAndCallMethod("ViewSubs", arguments, []);
    }
}

export class Alerts extends BaseSocket {
    get ClassName() {
        return "Simon.WebSocket.Workdesk.Alerts";
    }
}

export class Messages extends BaseSocket {
    get ClassName() {
        return "Simon.WebSocket.Workdesk.Messages";
    }
}

export class WorkdeskLayout extends BaseSocket {
    get ClassName() {
        return "Simon.WebSocket.Workdesk.WorkdeskLayout";
    }
    EditLayout(data) {
        this.validateAndCallMethod("EditLayout", arguments, [
            {
                name: "data",
                type: "Object[]",
                required: true,
                value: data,
            },
        ]);
    }
    GetLayout(layoutName) {
        this.validateAndCallMethod("GetLayout", arguments, [
            {
                name: "layoutName",
                type: "String",
                required: true,
                value: layoutName,
            },
        ]);
    }
    ResetToDefault() {
        this.validateAndCallMethod("ResetToDefault", arguments, []);
    }
}
