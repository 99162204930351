import {postAsync} from "@/utils/requests";
import tfiHelper from "@/utils/tfi-helper.js";

const endPoint = "/WebServices/TFI_API.asmx";
export default {
    state: {
        tiers: [],
        filteredTiers: [],
        version: {},
        featureSources: [],
        features: [],
        scoringCriteria: [],
        selectedTier: [],
        assessmentName: [],
        allVersions: [],
        currentAssessment: {},
    },
    getters: {
        getActiveTier(state) {
            return state.features.reduce((activeTier, currentFeature) => {
                if (currentFeature.IsActive === true) {
                    activeTier = state.filteredTiers.find(tier => tier.ID === currentFeature.TierID);
                }
                return activeTier;
            }, {});
        },
    },
    actions: {
        async getAssessment(context, id) {
            const {formatDateTime} = tfiHelper;
            const result = await postAsync("/WebServices/TFI_API.asmx/GetAssessment", {id: Number(id)});
            if (!result) {
                return new Promise((reject => {
                    reject();
                }));
            }

            let {Assessment, Tiers} = result;
            Assessment = {
                ...Assessment,
                CreatedDateTime: formatDateTime(Assessment.CreatedDateTime),
                LastModifiedDateTime: formatDateTime(Assessment.LastModifiedDateTime),
                CompletedDateTime: formatDateTime(Assessment.CompletedDateTime),
            };
            Tiers = Tiers.map(tier => ({
                ...tier,
                CreatedDateTime: formatDateTime(tier.CreatedDateTime),
            }));

            const currentAssessment = {
                ...Assessment,
                Tiers,
            };

            context.commit("setCurrentAssessment", currentAssessment);
            return new Promise (resolve => {
                resolve({data: currentAssessment});
            });
        },
        async getTiers(context) {
            const result = await postAsync(`${endPoint}/GetTiers`);
            const tiers = result.sort((a, b) => a.ID - b.ID).map(tier => ({
                ID: tier.ID,
                Code: tier.Code,
                Title: tier.Title,
            }));
            context.commit("setTiers", tiers);
        },
        async getAssessmentData(context, assessmentID) {
            const {Tiers, Features, Scores, FeatureScores} = await postAsync("/WebServices/TFI_API.asmx/GetFeatureScores", {assessmentID});
            Features[0].IsActive = true;
            context.commit("setFilteredTiers", Tiers);
            context.commit("setFeatures", Features);
            context.commit("setScoringCriteria", Scores);
            await context.dispatch("getFeatureSources");
            return new Promise((resolve) => {
                resolve({data: FeatureScores});
            });
        },
        async getFilteredTiers(context, tiers) {
            const result = await postAsync(`${endPoint}/GetFilteredTiers`, {tiers});
            const filteredTiers = result.sort((a, b) => a.ID - b.ID).map(tier => ({
                ID: tier.ID,
                Code: tier.Code,
                Title: tier.Title,
            }));
            context.commit("setFilteredTiers", filteredTiers);

            await context.dispatch("getLatestVersion", tiers);
            await context.dispatch("getFeatures", tiers);
            await context.dispatch("getFeatureSources");
            await context.dispatch("getScoringCriteria");
        },
        async getVersions(context) {
            const versions = await postAsync(`${endPoint}/GetVersions`);
            context.commit("setVersions", versions);
        },
        async getLatestVersion(context) {
            const version = await postAsync(`${endPoint}/GetLatestVersion`);
            context.commit("setVersion", version);
        },
        async getFeatures(context, tiers) {
            const result = await postAsync(`${endPoint}/GetFeatures`, {
                tiers,
                versionId: context.state.version.ID,
            });
            const features = result.map((f, index) => ({
                ID: f.ID,
                TierID: f.TierID,
                Title: f.Title,
                Description: f.Description,
                SortSeq: f.SortSeq,
                IsActive: index === 0 || false,
                IsFlagged: false,
                IsMarked: false,
                Note: '',
            }));
            context.commit("setFeatures", features);
        },
        async getScoringCriteria(context) {
            const featureIDs = context.state.features.map(f => f.ID);
            const result = await postAsync(`${endPoint}/GetScoringCriteria`, {featureIDs});
            const scoringCriteria = result.map(c => ({
                ID: c.ID,
                FeatureID: c.FeatureID,
                Score: c.Score,
                Description: c.Description,
                IsMarked: false,
            }));
            context.commit("setScoringCriteria", scoringCriteria);
        },
        async  getFeatureSources(context) {
            const featureIDs = context.state.features.map(f => f.ID);
            const featuresources = await postAsync(`${endPoint}/GetFeatureSources`, {featureIDs});
            const sources = await postAsync(`${endPoint}/GetSources`);
            const dataSourcesPromise = featuresources.map(async s => {
                return {
                    ID: s.ID,
                    FeatureID: s.FeatureID,
                    Source: sources.find(source => source.ID === s.SourceID).Description,
                };
            });

            const dataSources = await Promise.all(dataSourcesPromise);
            context.commit("setDataSources", dataSources);
        },
    },
    mutations: {
        setTiers(state, tiers) {
            state.tiers = tiers;
        },
        setFilteredTiers(state, tiers) {
            state.filteredTiers = tiers;
        },
        setVersions(state, versions) {
            state.allVersions = versions;
        },
        setVersion(state, version) {
            state.version = version;
        },
        setFeatures(state, features) {
            state.features = features;
        },
        setScoringCriteria(state, scoringCriteria) {
            state.scoringCriteria = scoringCriteria;
        },
        setDataSources(state, dataSources) {
            state.featureSources = dataSources;
        },
        setDescriptiveTier(state, fullTier) {
            state.fullTier = fullTier;
        },
        setCurrentAssessment(state, currentAssessment) {
            state.currentAssessment = currentAssessment;
        },
    },
};
