export default [
    {
        path: '/bookings/maintenance/categories/',
        component: () => import(/* webpackChunkName: "bookings" */ "@/pages/bookings/maintenance/BookingCategories"),
        alias: '/WebModules/ResourceBookings/Maintenance/BookingCategories/MaintainBookingCategories.aspx',
        meta: {menu_id: 'ResourceBookings'},
    },
    {
        path: '/bookings/maintenance/categories/:categoryCode/',
        component: () => import(/* webpackChunkName: "bookings" */ "@/pages/bookings/maintenance/BookingItems"),
        meta: {
            menu_id: 'ResourceBookings',
            title: "Resource Items",
        },
        props: true,
    },
    {
        path: '/bookings/maintenance/categories/:categoryCode/:resourceCode/',
        component: () => import(/* webpackChunkName: "bookings" */ "@/pages/bookings/maintenance/BookingItem"),
        meta: {
            menu_id: 'ResourceBookings',
            title: "Resource Item",
        },
        props: true,
    },
    {
        path: '/bookings/maintenance/models/',
        component: () => import(/* webpackChunkName: "bookings" */ "@/pages/bookings/maintenance/BookingModels"),
        alias: '/WebModules/ResourceBookings/Maintenance/BookingModels/MaintainBookingModels.aspx',
        meta: {
            menu_id: 'ResourceBookings',
            title: "Booking Models",
        },
    },
    {
        path: '/bookings/maintenance/models/:code/',
        component: () => import(/* webpackChunkName: "bookings" */ "@/pages/bookings/maintenance/BookingModelsView"),
        meta: {
            menu_id: 'ResourceBookings',
            title: 'View Booking Model Details',
        },
        props: true,
    },
];
