// Subfolders
import admin from "./admin";
import bookings from "./bookings";
import cocurricular from "./cocurricular";
import communication from "./communication";
import components from "./components";
import connect_surveys from "./connect_surveys";
import debug from "./debug";
import forums from "./forums";
import help from "./help";
import jump from "./jump";
import profiles from "./profiles";
import schoollinks from "./school-links";
import studentassessment from "./studentassessment";
import studentattendance from "./studentattendance";
import socialbehaviour from "./social-behaviour";
import parentLogin from "./parent-login";
import coursebuilder from "./course-builder";
import schoolMessages from "./school_messages";
import learningAreas from "./learning-areas";

// Modules
import PathNotFound from "../pages/errors/PathNotFound";

const routes = [
    // This route will force urls to end with a trailing slash, to help users that type or edit URLs manually
    // or to recover from badly written links.
    // It must remain first.
    {
        path: "/(.*[^/])",
        pathToRegexpOptions: {
            strict: true,
            end: true,
        },
        redirect(to) {
            return to.path + "/";
        },
    },
    // Add Routes below, maintaining alphabetical order.
    ...admin,
    ...bookings,
    ...cocurricular,
    ...communication,
    ...components,
    ...connect_surveys,
    ...coursebuilder,
    ...debug,
    ...forums,
    ...help,
    ...jump,
    ...learningAreas,
    ...parentLogin,
    ...profiles,
    ...schoollinks,
    ...schoolMessages,
    ...socialbehaviour,
    ...studentassessment,
    ...studentattendance,
    {
        path: '',
        alias: "/workdesk/",
        component: () => import(/* webpackChunkName: "workdesk" */ "@/pages/workdesk/Home"),
        meta: {title: "Workdesk"},
    },
    // The following route is for handling 404s, and must remain last.
    {
        path: '*',
        component: PathNotFound,
        meta: {title: "Resource not found"},
    },
];
export default routes;
