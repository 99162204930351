import Empty from "@/pages/admin/Empty";

export default [
    {
        path: '/admin/system/',
        component: Empty,
        alias: '/WebModules/SystemAdministration/SystemAdministration.aspx',
        meta: {menu_id: 'SYSTEMADMIN'},
    },
    {
        path: '/admin/system/styles/',
        component: () => import(/* webpackChunkName: "admin.system" */ "@/pages/admin/system/StyleColours"),
        alias: '/WebModules/SystemAdministration/Styles.aspx',
        meta: {menu_id: 'SYSTEMADMIN'},
    },
    {
        path: '/admin/system/styles/workdesk/',
        component: () => import(/* webpackChunkName: "admin.system" */ "@/pages/admin/system/StyleColours"),
        alias: '/WebModules/SystemAdministration/Styles.aspx',
        // Intentionally missing meta, so it registers as a workdesk page.
    },
    {
        path: '/admin/system/pastoral-chains/',
        alias: '/WebModules/SystemAdministration/PastoralChains/MaintainPastoralChains.aspx',
        component: () => import(/* webpackChunkName: "admin.system" */ "@/pages/admin/system/PastoralChains"),
        meta: {
            menu_id: "SYSTEMADMIN",
            title: "Pastoral Chains - Maintain",
        },
    },
];
