export default {
    methods: {
        /**
         * @public
         * Bring the component into focus.
         */
        focus() {
            this.$refs.input.focus();
        },
        /**
         * @public
         * Return focus to the body of the document.
         *
         * *Note: You're usually better off focusing a different component instead, so the user can continue their workflow more seamlessly.*
         */
        blur() {
            this.$refs.input.blur();
        },
        /**
         * @public
         * Reset the value of this component.
         */
        reset() {
            this.$refs.input.reset();
        },
    },
};
