<template>
    <div class="menu">
        <div class="d-none d-md-flex">
            <div class="indent d-none d-lg-block" />
            <nested-menu
                v-for="item in toolbarLinks"
                :key="item.MenuItemID"
                :item="item"
            />
        </div>
        <div class="d-flex d-md-none">
            <nested-menu
                v-for="item in toolbarLinksMobile"
                :key="item.MenuItemID"
                :item="item"
            />
        </div>
    </div>
</template>

<script>
    import NestedMenu from "./NestedMenu";

    export default {
        name: "Toolbar",
        components: {
            NestedMenu,
        },
        props: {
            menuId: {
                type: String,
                required: true,
            },
            pageTitle: {
                type: String,
                required: true,
            },
        },
        computed: {
            toolbarLinks() {
                let items = this.$store.state.websiteMenuItems.filter(x => x.ClassicNavOnly !== false);

                let options = items.filter(x => this.menuId === x.MenuID.toLowerCase());
                function getChildren(id) {
                    return options.filter(x => x.ParentMenuID === id).map(function (link) {
                        let newLink = {...link};
                        newLink.children = getChildren(link.MenuItemID);
                        return newLink;
                    });
                }
                return getChildren("");
            },
            toolbarLinksMobile() {
                return [{
                    Caption: this.pageTitle,
                    MenuID: "!root!",
                    children: this.toolbarLinks,
                    width: "100%",
                }];
            },
        },
    };
</script>

<style lang="scss" scoped>
    .menu {
        background: white;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
            0px 4px 5px 0px rgba(0, 0, 0, 0.14),
            0px 1px 10px 0px rgba(0, 0, 0, 0.12);

        .indent {
            padding-left: 15px;
        }
    }
</style>
