export default [
    {
        path: "/learning-areas/maintenance/task-score-individualised-reasons",
        component: () => import(/* webpackChunkName: "learning-areas" */ "@/pages/learning-areas/maintenance/TaskScoreIndividualisedReasons"),
        meta: {
            menu_id: "LearningAreas",
            menu_item_id: "LearningAreasMaintTaskScoreIndividualisedReasons",
            title: 'Maintain Learning Areas Tasks Score Individualised Reasons',
        },
    },
];
