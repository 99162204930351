import { render, staticRenderFns } from "./GenericConfirmation.vue?vue&type=template&id=2c263a63&scoped=true&"
import script from "./GenericConfirmation.vue?vue&type=script&lang=js&"
export * from "./GenericConfirmation.vue?vue&type=script&lang=js&"
import style0 from "./GenericConfirmation.vue?vue&type=style&index=0&id=2c263a63&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c263a63",
  null
  
)

export default component.exports