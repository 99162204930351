import {postAsync, uncompressDictionaries} from "@/utils/requests";
import {asArray} from "@/utils/array";
import {snackbar} from "@/utils/generic_modals";

const user = {
    name: "",
    guid: "",
    communityId: null,
    masquerade: null,
    canMasquerade: false,
    loaded: false,
    failure: null,
    type: null,
    actions: {},
    config: {},
    roles: [],
    timetableDayDescription: null,
    isSiteAdmin: false,
    isSysAdmin: false,
};


// The purpose of this global (which can be accessed via $user) is to give a read only insight to the current user.
export const userGlobal = {
    get loaded() {
        return user.loaded;
    },
    get name() {
        return user.name;
    },
    get guid() {
        return user.guid;
    },
    get communityId() {
        return user.communityId;
    },
    get masquerade() {
        return user.masquerade;
    },
    get canMasquerade() {
        return user.canMasquerade;
    },
    get isStaff() {
        return user.type === "Staff";
    },
    get timetableDayDescription() {
        return user.timetableDayDescription;
    },
    get actions() {
        return user.actions;
    },
    get isSiteAdmin() {
        return user.isSiteAdmin;
    },
    get isSysAdmin() {
        return user.isSysAdmin;
    },
    getConfig(name) {
        if (name in user.config) {
            return user.config[name];
        }
        console.warn("Attempted to read unknown config option:", name);
        return null;
    },
    hasRole(code) {
        if (code in user.roles) {
            return user.roles[code];
        }
        console.warn("Unknown role code: ", code);
        return false;
    },
    can(...actions) {
        if (!user.actions) {
            // Probably hasn't loaded yet.
            // This can happen for things in the navbar.
            // Assume no until we get a confirmed yes.
            return false;
        }

        for (const action of actions.flatMap((x) => asArray(x))) {
            const access = user.actions[action];
            if (access === undefined) {
                console.trace(`Invalid action; ${action}`);
                snackbar.error(`Invalid action; ${action}`);
                return false;
            } else if (access) {
                return true;
            }
        }
        return false;
    },
};


// This mixin should only be required by things that need to manipulate the user object.
// Everything else should just use $user.
export default {
    data() {
        return {
            user,
        };
    },
    methods: {
        async loadUserData(globallyLoad = true) {
            let userInfo;
            if (globallyLoad) {
                this.$store.commit("startGlobalLoading");
            }
            try {
                userInfo = await postAsync("Default.asmx/UserInformation");
            } catch (error) {
                user.failure = "Could not load user profile.";
                console.error(error);
                return;
            } finally {
                user.loaded = true;
                if (globallyLoad) {
                    this.$store.commit("endGlobalLoading");
                }
            }
            user.name = userInfo.name;
            user.canMasquerade = userInfo.canMasquerade;
            user.masquerade = userInfo.masquerade;
            user.guid = userInfo.guid;
            user.communityId = userInfo.communityId;
            user.type = userInfo.userType;
            user.config = userInfo.config;
            user.roles = userInfo.roles;
            user.timetableDayDescription = userInfo.timetableDayDescription;
            user.actions = userInfo.actions;
            user.isSiteAdmin = userInfo.isSiteAdmin;
            user.isSysAdmin = userInfo.isSysAdmin;
            this.$store.commit({
                type: "setWebsiteMenuItems",
                websiteMenus: uncompressDictionaries(userInfo.menus),
                websiteMenuItems: uncompressDictionaries(userInfo.menuItems),
            });
            this.$store.commit("setAcademicSemester", userInfo.academicSemester);
            this.version = userInfo.version;
        },
    },
};
