// https://github.com/froala/vue-froala-wysiwyg#readme
import Vue from 'vue';

// Import Froala Editor
import 'froala-editor/js/plugins.pkgd.min.js';

// Import third party plugins
import 'froala-editor/js/third_party/embedly.min';
import 'froala-editor/js/third_party/image_tui.min';

// Import Froala Editor css files.
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Import and use Vue Froala lib.
import VueFroala from 'vue-froala-wysiwyg';
Vue.use(VueFroala);
